<template>
    <v-container>
        <v-layout align-center>
            <v-flex xs10>
                <h3 class="secondary--text">Todas Propostas</h3>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 md4 text-xs-right>
                <v-text-field label="Pesquisar" v-model="filtro" color="secondary" append-icon="search"></v-text-field>
            </v-flex>
            <v-flex xs1 text-xs-right>
                <v-tooltip color="secondary" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" slot="activator" dark small color="secondary" @click="buscar" depressed>
                            <v-icon>mdi-sync</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar Dados</span>
                </v-tooltip>
            </v-flex>
        </v-layout>
        <v-layout justify-space-around row wrap>
            <v-flex xs12>
                <v-card class="default-border" flat text>
                    <v-data-table :headers="headers" :items="listaModelo" class="elevation-1 table-motoristas"
                        :search="filtro" :sort-by="['dataInclusao']" :sort-desc="[true]" :items-per-page="20"
                        :footer-props="{
                            itemsPerPageAllText: 'Todos',
                            itemsPerPageText: 'Itens por página',
                        }">
                        <template v-slot:[`item.valor`]="{ item }">
                            {{ customFormatComputed.formatarReais(item.valor) }}
                        </template>
                        <template v-slot:[`item.valorTAC`]="{ item }">
                            {{ customFormatComputed.formatarReais(item.valorTAC) }}
                        </template>
                        <template v-slot:[`item.valorIOF`]="{ item }">
                            {{ customFormatComputed.formatarReais(item.valorIOF) }}
                        </template>
                        <template v-slot:[`item.valorJuros`]="{ item }">
                            {{ customFormatComputed.formatarReais(item.valorJuros) }}
                        </template>
                        <template v-slot:[`item.valorLiquido`]="{ item }">
                            {{ customFormatComputed.formatarReais(item.valorLiquido) }}
                        </template>
                        <template v-slot:[`item.jurosMesalPercentual`]="{ item }">
                            {{ item.jurosMesalPercentual + ' %' }}
                        </template>
                        <template v-slot:[`item.jurosTotalPercentual`]="{ item }">
                            {{ item.jurosTotalPercentual + ' %' }}
                        </template>
                        <template v-slot:[`item.dataTomada`]="{ item }">
                            {{
                                customFormatComputed.toLocaleDateString(item.dataTomada)
                            }}
                        </template>
                        <template v-slot:[`item.dataPrevistaQuitacao`]="{ item }">
                            {{
                                customFormatComputed.toLocaleDateString(item.dataPrevistaQuitacao)
                            }}
                        </template>
                        <template v-slot:[`item.dataQuitacao`]="{ item }">
                            {{
                                customFormatComputed.toLocaleDateString(item.dataQuitacao)
                            }}
                        </template>
                        <template v-slot:[`item.dataInclusao`]="{ item }">
                            {{
                                customFormatComputed.toLocaleDateTimePtBrString(item.dataInclusao)
                            }}
                        </template>
                        <template v-slot:[`item.controle`]="{ item }">
                            <v-layout justify-center>
                                <v-tooltip color="secondary" bottom>
                                    <template v-slot:activator="{ on }">
                                        <td>
                                            <v-btn small depressed fab v-on="on" color="secondary"
                                                class="ma-2 px-1 icon-edit" @click="abrirModalStatus(item)">
                                                <v-icon style="font-size: 16px">mdi-file-find-outline</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <span>Ver Proposta {{ item.nome }}</span>
                                </v-tooltip>
                            </v-layout>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
        <template>
            <v-dialog v-model="dialogStatus" persistent>
                <v-card>
                    <v-form v-model="formularioCadastroStatusValido" ref="formularioCadastroStatusRef">
                        <v-card-title>
                            <span class="text-h5">Detalhes da Proposta</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container grid-list-lg>
                                <v-layout row wrap>
                                    <v-flex xs12 md6>Nome: <b>{{ itemModel.funcionario.nome }}</b></v-flex>
                                    <v-flex xs12 md6>CPF: <b>{{
                                        customFormatComputed.formatCPFCNPJNumber(itemModel.funcionario.numeroDocumento)
                                            }}</b></v-flex>
                                    <v-flex xs12 md6>Email: <b>{{ itemModel.funcionario.email }}</b></v-flex>
                                    <v-flex xs12 md6>Salário: <b>{{
                                        customFormatComputed.formatarReais(itemModel.funcionario.salario)
                                            }}</b></v-flex>
                                    <v-flex xs12 md6>Limite Disponível: <b>{{
                                        customFormatComputed.formatarReais(itemModel.funcionario.limiteDisponivel)
                                            }}</b></v-flex>
                                    <v-flex xs12 md6>Func. Padrão Juros Mensal: <b>{{ itemModel.funcionario.jurosMensal
                                            }}</b>%</v-flex>
                                    <v-flex xs12 md6>Usuário Criador Func.: <b>{{
                                        itemModel.funcionario.usuario.nome }}</b></v-flex>
                                    <v-flex xs12 md6>Conta Func: <b>{{
                                        itemModel.funcionario.conta.numeroConta }}</b></v-flex>
                                    <v-flex xs12 md6>Empresa: <b>{{
                                        itemModel.empresa.nome }}</b></v-flex>
                                    <v-flex xs12><v-divider></v-divider></v-flex>
                                    <v-flex xs12 md6>Data Tomada de Crédito: <b>{{
                                        customFormatComputed.toLocaleDateString(itemModel.dataTomada)
                                            }}</b></v-flex>
                                    <v-flex xs12 md6>Data Prevista para Quitação: <b>{{
                                        customFormatComputed.toLocaleDateString(itemModel.dataPrevistaQuitacao)
                                            }}</b></v-flex>
                                    <v-flex xs12 md6>Data Quitação: <b>{{
                                        customFormatComputed.toLocaleDateString(itemModel.dataQuitacao)
                                            }}</b></v-flex>
                                    <v-flex xs12><v-divider></v-divider></v-flex>
                                    <v-flex xs12 md6>Juros Mesal Fechado Percentual: <b>{{
                                        itemModel.jurosMesalPercentual }} %</b></v-flex>
                                    <v-flex xs12 md6>Juros Total Percentual: <b>{{
                                        itemModel.jurosTotalPercentual }} %</b></v-flex>
                                    <v-flex xs12 md6>Valor da Proposta: <b>{{
                                        customFormatComputed.formatarReais(itemModel.valor)
                                            }}</b></v-flex>
                                    <v-flex xs12 md6>Valor IOF: <b>{{
                                        customFormatComputed.formatarReais(itemModel.valorIOF)
                                            }}</b></v-flex>
                                    <v-flex xs12 md6>Valor Juros: <b>{{
                                        customFormatComputed.formatarReais(itemModel.valorJuros)
                                            }}</b></v-flex>
                                    <v-flex xs12 md6>Valor TAC: <b>{{
                                        customFormatComputed.formatarReais(itemModel.valorTAC)
                                            }}</b></v-flex>
                                    <v-flex xs12 md6>Valor Líquido: <b>{{
                                        customFormatComputed.formatarReais(itemModel.valorLiquido)
                                            }}</b></v-flex>
                                    <v-flex xs12 md6>Valor a ser depositado: <b>{{
                                        customFormatComputed.formatarReais(itemModel.valorLiquido - itemModel.valorTAC)
                                            }}</b></v-flex>
                                    <v-flex xs12 md6>Download CCB:
                                        <v-btn small color="secondary" depressed
                                            @click="baixaCCB(itemModel.idPropostaCredito)"
                                            :disabled="itemModel.tipoStatusPropostaCredito.idTipoStatusPropostaCredito < 5">
                                            <v-icon>mdi-cloud-download-outline</v-icon>
                                        </v-btn>
                                    </v-flex>

                                    <v-flex xs12><v-divider></v-divider></v-flex>
                                    <v-flex xs12><v-data-table :headers="headersStatus" :items="itemModel.listaStatus"
                                            class="elevation-1 table-motoristas" :sort-by="['dataInclusao']"
                                            :sort-desc="[true]" :items-per-page="5" :footer-props="{
                                                itemsPerPageAllText: 'Todos',
                                                itemsPerPageText: 'Itens por página',
                                            }">
                                            <template v-slot:[`item.empresa`]="{ item }">
                                                {{ !item.empresa ? "Sem Vinculo" : item.empresa.apelido }}
                                            </template>
                                            <template v-slot:[`item.numeroDocumento`]="{ item }">
                                                {{ customFormatComputed.formatCPFCNPJNumber(item.numeroDocumento) }}
                                            </template>
                                            <template v-slot:[`item.ativo`]="{ item }">
                                                {{ item.ativo ? "Ativo" : "Inativo" }}
                                            </template>
                                            <template v-slot:[`item.dataInclusao`]="{ item }">
                                                {{
                                                    customFormatComputed.toLocaleDateTimePtBrString(item.dataInclusao)
                                                }}
                                            </template>
                                        </v-data-table></v-flex>
                                    <v-flex xs12><v-divider></v-divider></v-flex>
                                    <v-flex xs12 md6>Status Atual: <b>{{
                                        itemModel.tipoStatusPropostaCredito.nome
                                            }}</b></v-flex>
                                    <v-flex xs12 md6><v-select v-model="cadastroModel.tipoStatusPropostaCredito"
                                            color="secondary" :rules="regras.branco" :items="listaStatus"
                                            item-text="nome" prepend-icon="account_balance"
                                            label="Trocar Status da Proposta" required return-object></v-select>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-textarea v-model="cadastroModel.observacao" color="secondary"
                                            :rules="regras.branco" label="Observação" type="text" required></v-textarea>
                                    </v-flex>
                                    <v-flex xs12><v-divider></v-divider></v-flex>
                                    <v-flex xs12 md6>Data Inclusão: <b>{{
                                        customFormatComputed.toLocaleDateTimePtBrString(itemModel.dataInclusao)
                                            }}</b></v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="secondary" text @click="closeStatus">
                                Fechar
                            </v-btn>
                            <v-btn color="secondary" depressed :disabled="disableBtnFinalizar || !formValid"
                                @click="finalizar">
                                Salvar
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
        </template>
        <v-snackbar :timeout="3200" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
            v-model="snackbarModel.mostrar" bottom multi-line>
            {{ snackbarModel.text }}
            <v-btn dark text @click.native="snackbarModel.mostrar = false">Fechar</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";

export default {
    name: "PropostasAdmin",
    data: () => ({
        dialogStatus: false,
        formularioCadastroStatusValido: false,
        disableBtnFinalizar: false,
        cadastroModel: {
            idPropostaCredito: null,
            tipoStatusPropostaCredito: null,
            observacao: null
        },
        itemModel: {
            funcionario: {
                nome: null,
                numeroDocumento: null,
                email: null,
                salario: null,
                limiteDisponivel: null,
                jurosMensal: null,
                usuario: { nome: null },
                conta: { numeroConta: null }
            },
            empresa: { nome: null },
            tipoStatusPropostaCredito: { nome: null },
            listaStatus: []
        },
        snackbarModel: {
            mostrar: false,
            sucesso: false,
            text: "",
        },
        filtro: "",
        headers: [
            { text: "Empresa", value: "empresa.apelido" },
            { text: "Nome", value: "funcionario.nome" },
            { text: "Cod. Proposta", value: "idPropostaCredito" },
            { text: "Status Proposta", value: "tipoStatusPropostaCredito.nome" },
            { text: "Valor", value: "valor" },
            { text: "Líquido", value: "valorLiquido" },
            { text: "Juro a.m. %", value: "jurosMesalPercentual" },
            { text: "Juro %", value: "jurosTotalPercentual" },
            { text: "Data Tomada", value: "dataTomada" },
            { text: "Data Prev. Quitação", value: "dataPrevistaQuitacao" },
            { text: "Data Inclusão", value: "dataInclusao" },
            { text: "", value: "controle", sortable: false },
        ],
        headersStatus: [
            { text: "Status Proposta", value: "tipoStatusPropostaCredito.nome" },
            { text: "Usuário", value: "usuario.nome" },
            { text: "Observação", value: "observacao" },
            { text: "Data Inclusão", value: "dataInclusao" }
        ],
        listaModelo: [],
        listaStatus: [
            { idTipoStatusPropostaCredito: 2, nome: 'Recusado Instituição' },
            { idTipoStatusPropostaCredito: 3, nome: 'Pendente de Assinatura' },
            { idTipoStatusPropostaCredito: 6, nome: 'Cancelar Operação' },
            { idTipoStatusPropostaCredito: 8, nome: 'Efetuar Pagamento' }
        ],
        regras: {
            branco: [(v) => !!v || "Você não pode deixar este campo em branco"],
        },
    }),
    mounted: function () {
        this.buscar()
        this.externalSnackbarMessage()
    },
    computed: {
        customFormatComputed: function () {
            return customFormat;
        },
        cipherComputed: function () {
            return cipher;
        },
        formValid: function () {
            return this.formularioCadastroStatusValido;
        },
    },
    methods: {
        abrirModalStatus(item) {
            const self = this;
            self.itemModel = item

            self.cadastroModel.idPropostaCredito = item.idPropostaCredito;
            self.disableBtnFinalizar = false;
            self.dialogStatus = true;
        },
        closeStatus() {
            const self = this;

            self.cadastroModel.idPropostaCredito = null
            self.cadastroModel.tipoStatusPropostaCredito = null
            self.cadastroModel.observacao = null
            self.dialogStatus = false;
        },
        finalizar() {
            const self = this
            self.$store.commit("startLoading");
            if (self.$refs.formularioCadastroStatusRef.validate()) {

                self.$http
                    .post(
                        `${process.env.VUE_APP_URL_API}/proposta-credito/altera-status`, self.cadastroModel,
                        {
                            headers: {
                                Authorization:
                                    `Bearer ${localStorage.getItem("token")}`,
                            },
                        }
                    )
                    .then(function (response) {
                        const data = response.data
                        if (data.sucesso) {
                            self.snackbarModel.text = data.mensagem;
                            self.snackbarModel.mostrar = true;
                            self.snackbarModel.sucesso = data.sucesso;
                            self.disableBtnFinalizar = data.sucesso;
                            self.buscar()
                            self.closeStatus()
                        } else {
                            self.snackbarModel.text = data.mensagem;
                            self.snackbarModel.mostrar = true;
                            self.snackbarModel.sucesso = data.sucesso;
                            self.disableBtnFinalizar = data.sucesso;
                        }
                        self.$store.commit("endLoading");
                    })
                    .catch(function (error) {
                        self.$store.commit("endLoading");
                        self.snackbarModel.text = error.data.mensagem;
                        self.snackbarModel.mostrar = true;
                        self.snackbarModel.sucesso = false;
                        console.log(error)
                    })
            }
        },
        baixaCCB(idPropostaCredito) {
            const self = this;
            self.$store.commit("startLoading");
            self.$http
                .get(
                    `${process.env.VUE_APP_URL_API}/proposta-credito/download-ccb/${idPropostaCredito}`,
                    {
                        responseType: "blob",
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then(function (response) {
                    let blob = new Blob([response.data], {
                        type: response.headers["content-type"],
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = idPropostaCredito + '.pdf';
                    link.click();
                    self.$store.commit("endLoading");
                }).catch(function (error) {
                    console.error(error)
                    self.snackbarModel.text = "CCB não encontrada";
                    self.snackbarModel.mostrar = true;
                    self.snackbarModel.sucesso = false;
                    self.$store.commit("endLoading");
                });
        },
        buscar() {
            const self = this;
            self.$store.commit("startLoading");

            self.$http
                .get(
                    `${process.env.VUE_APP_URL_API}/proposta-credito/admin/busca`,
                    {
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then(function (response) {
                    self.listaModelo = response.data;
                    self.$store.commit("endLoading")
                }).catch(function (error) {
                    self.$store.commit("endLoading");
                    console.log(error);
                });
        },
        externalSnackbarMessage: function () {
            const self = this;
            if (self.$route.params.snackbarMostrar) {
                self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
                self.snackbarModel.text = self.$route.params.snackbarText;
                self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
            }
        },
        go: function (routeName, routeParams) {
            const self = this;
            if (!routeParams) {
                self.$router.push({ name: routeName });
            } else {
                self.$router.push({ name: routeName, params: routeParams });
            }
        }
    }
};
</script>