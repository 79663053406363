import CryptoJS from 'crypto-js'

export default {
  encrypt: function (value) {
    return CryptoJS.AES.encrypt(value.toString(), process.env.VUE_APP_CRYPTO_KEY).toString();
  },
  encryptUrl: function (value) {
    return encodeURIComponent(CryptoJS.AES.encrypt(value.toString(), process.env.VUE_APP_CRYPTO_KEY).toString());
  },
  decrypt: function (value) {
    const bytes = CryptoJS.AES.decrypt(value, process.env.VUE_APP_CRYPTO_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
