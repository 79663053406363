<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="value"
      persistent
      content
      content-class="centered-dialog elevation-0"
    >
      <v-container fill-height>
        <v-layout column justify-center align-center>
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            :color="progressColor"
          ></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  name: "loading",
  data: function () {
    return {};
  },
  computed: {
    value: function () {
      return this.$store.state.loadingPage;
    },
  },
  props: {
    progressColor: { type: String, default: "progressBarColor" },
  },
};
</script>

<style>
.dialog.centered-dialog {
  box-shadow: none;
  border-radius: 6px;
  width: auto;
  color: whitesmoke;
}
</style>
