<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm8>
        <v-card class="elevation-12 default-border">
          <v-toolbar color="secondary">
            <v-toolbar-title>{{
              this.$route.params.id
                ? "Edição de Split " + cadastroModel.nome
                : "Cadastro de Split"
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container grid-list-lg>
              <v-form v-model="formularioCadastroValido" ref="formularioCadastroRef">
                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.numeroContaAlvo" color="secondary" :rules="regras.branco"
                      prepend-icon="mdi-text-box-search" label="Numero da Conta Alvo" type="text"
                      v-mask="'###########-#'" required></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field ref="txtValorAlvoFormatado" v-model="valorAlvoFormatado" color="secondary"
                      :rules="regras.branco" prepend-icon="mdi-currency-usd" label="Valor Alvo" type="text" required
                      v-money="money"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-switch :label="`${cadastroModel.ativo ? 'Ativo' : 'Inativo'}`" v-model="cadastroModel.ativo"
                      color="primary"></v-switch>
                  </v-flex>
                  <v-flex xs12 md12>
                    <template>
                      <v-data-table :headers="headers" :items="cadastroModel.listaContasValor" sort-by="calories"
                        class="elevation-1">
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-toolbar-title>Contas Split</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" max-width="500px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                  Adicionar Conta
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="editedItem.numeroConta" label="Numero Conta"
                                          v-mask="'###########-#'" required prepend-icon="mdi-text-box-search"
                                          :rules="regras.branco"> </v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="editedItem.valorFormatado" label="Valor" required
                                          :rules="regras.branco" prepend-icon="mdi-currency-usd"></v-text-field>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="close">
                                    Fechar
                                  </v-btn>
                                  <v-btn color="blue darken-1" text @click="save">
                                    Salvar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Tem certeza de que deseja excluir esta
                                  conta?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">Fechar</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                          </v-icon>
                          <v-icon small @click="deleteItem(item)">
                            mdi-delete
                          </v-icon>
                        </template>
                        <template v-slot:no-data>
                        </template>
                      </v-data-table>
                    </template>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn id="voltar" color="primary" class="grey--text text--darken-1" :to="{ name: 'Empresas' }" text>Voltar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="finalizar" color="secondary" class="default-border" depressed @click="finalizar()"
              :disabled="disableBtnFinalizar">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar :timeout="5000" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`" bottom multi-line
      v-model="snackbarModel.mostrar">
      {{ snackbarModel.text }}
      <v-btn text dark @click.native="
        `${snackbarModel.sucesso
          ? go('Empresas')
          : (snackbarModel.mostrar = false)
        }`
        ">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import customFormat from "@/helpers/custom-format.js";
import cipher from "@/helpers/cipher.js";
import jwt from "@/helpers/jwt";
import { VMoney } from 'v-money'

export default {
  name: "CadastroSplit",
  data: () => ({
    formularioCadastroValido: false,
    disableBtnFinalizar: false,
    valorAlvoFormatado: null,
    cadastroModel: {
      idSplitPagamento: null,
      valorAlvo: null,
      ativo: true,
      numeroContaAlvo: null,
      listaContasValor: [],
    },
    snackbarModel: {
      mostrar: false,
      text: "",
      sucesso: false,
    },
    regras: {
      branco: [(v) => !!v || "Você não pode deixar este campo em branco"]
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
      masked: false
    },
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Numero Conta',
        align: 'start',
        sortable: false,
        value: 'numeroConta',
      },
      { text: 'Valor', value: 'valorFormatado' },
      { text: '...', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      idSplitPagamentoItem: 0,
      numeroConta: '',
      valor: null,
      valorFormatado: ''
    },
    defaultItem: {
      idSplitPagamentoItem: 0,
      numeroConta: '',
      valor: null,
      valorFormatado: ''
    },
  }),
  directives: { money: VMoney },
  computed: {
    formValid: function () {
      return this.formularioCadastroValido;
    },
    customFormatComputed: function () {
      return customFormat;
    },
    formTitle() {
      return this.editedIndex === -1 ? 'Nova Numero Conta' : 'Editar Numero Conta'
    }
  },
  watch: {
    valorAlvoFormatado: function (val) {
      this.cadastroModel.valorAlvo = customFormat.removeMoneyFormat(val);
    },
    'editedItem.valorFormatado': function (val) {
      this.editedItem.valor = val.replaceAll('.', '').replaceAll(',', '');
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  mounted: function () {
    this.buscar();
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.cadastroModel.listaContasValor.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = this.cadastroModel.listaContasValor.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.cadastroModel.listaContasValor.splice(this.editedIndex, 1)
      this.closeDelete()
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.cadastroModel.listaContasValor[this.editedIndex], this.editedItem)
      } else {
        this.cadastroModel.listaContasValor.push(this.editedItem)
      }
      this.close()
    },
    formatMoney(valor) {
      return ("R$ " + valor).replace(".", ",");
    },
    buscar: function () {
      const self = this;
      const item = jwt.decryptToken();
      if (item != null) {
        if (
          this.$route.params.id &&
          cipher.decrypt(this.$route.params.id)
        ) {
          self.$store.commit("startLoading");
          console.log(cipher.decrypt(this.$route.params.id))
          self.cadastroModel.numeroContaAlvo = cipher.decrypt(this.$route.params.id)
          self.$http
            .get(
              `${process.env.VUE_APP_URL_API}/conta/split-pagamento/busca/` +
              cipher.decrypt(this.$route.params.id),
              {
                headers: {
                  Authorization:
                    `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then(function (response) {
              if (response.data.idSplitPagamento != 0) {
                self.cadastroModel.idSplitPagamento = response.data.idSplitPagamento;
                self.cadastroModel.valorAlvo = response.data.valorAlvo;
                self.cadastroModel.ativo = response.data.ativo;
                self.cadastroModel.numeroContaAlvo = response.data.numeroContaAlvo;

                response.data.listaContasValor.forEach(e => {
                  self.cadastroModel.listaContasValor.push({
                    idSplitPagamentoItem: e.idSplitPagamentoItem,
                    numeroConta: e.numeroConta,
                    valor: e.valor,
                    valorFormatado: (e.valor / 100).toFixed(2)
                  })
                });

                self.$refs.txtValorAlvoFormatado.$el.getElementsByTagName(
                  "input"
                )[0].value = ("R$ " + response.data.valorAlvo).replace(".", ",");
              }

              self.$store.commit("endLoading");
            }).catch(function (error) {
              self.$store.commit("endLoading");
              self.snackbarModel.text = error.response.data.mensagem;
              self.snackbarModel.sucesso = false;
              self.snackbarModel.mostrar = true;
              self.disableBtnFinalizar = false;
              console.log(error);
            });
        }
      }
    },
    finalizar: function () {
      const self = this;
      self.$store.commit("startLoading");
      // if (self.$refs.formularioCadastroRef.validate()) {
      const item = jwt.decryptToken();
      if (item != null) {
        if (self.$route.params.id) {
          self.$http
            .post(
              `${process.env.VUE_APP_URL_API}/conta/split-pagamento/cadastro`,
              self.cadastroModel,
              {
                headers: {
                  Authorization:
                    `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then(function (response) {
              self.$store.commit("endLoading");
              const data = response.data;
              if (data.sucesso) {
                self.disableBtnFinalizar = data.sucesso;

                self.go("Empresas", {
                  snackbarText: data.mensagem,
                  snackbarMostrar: true,
                  snackbarSucesso: data.sucesso,
                });

              } else {
                self.snackbarModel.text = data.mensagem;
                self.snackbarModel.mostrar = true;
                self.snackbarModel.sucesso = data.sucesso;
                self.disableBtnFinalizar = data.sucesso;
              }
            })
            .catch(function (error) {
              self.$store.commit("endLoading");
              self.snackbarModel.text = error.response.data.mensagem;
              self.snackbarModel.sucesso = false;
              self.snackbarModel.mostrar = true;
              self.disableBtnFinalizar = false;
            });
        }
      } else {
        self.go("Login");
      }
      // }
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (this.$router.currentRoute.name != routeName) {
        if (!routeParams) {
          self.$router.push({ name: routeName });
        } else {
          self.$router.push({ name: routeName, params: routeParams });
        }
      }
    },
  },
};
</script>