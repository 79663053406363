<template>
    <v-container>
        <v-layout align-center>
            <v-flex xs10>
                <h3 class="secondary--text">Funcionários</h3>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 md4 text-xs-right>
                <v-text-field label="Pesquisar" v-model="filtro" color="secondary" append-icon="search"></v-text-field>
            </v-flex>
            <v-flex xs1 text-xs-right>
                <v-tooltip color="secondary" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" slot="activator" dark small color="secondary"
                            @click="go('CadastroFuncionario')" depressed>
                            <v-icon>add</v-icon>
                        </v-btn>
                    </template>
                    <span>Adicionar novo Funcionário</span>
                </v-tooltip>
            </v-flex>
            <v-flex xs1 text-xs-right>
                <v-tooltip color="secondary" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" slot="activator" dark small color="secondary"
                            @click="go('CadastroFuncionarioLote')" depressed>
                            <v-icon>mdi-receipt-text-plus-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Adicionar novo Lote de Funcionários</span>
                </v-tooltip>
            </v-flex>
        </v-layout>
        <v-layout justify-space-around row wrap>
            <v-flex xs12>
                <v-card class="default-border" flat text>
                    <v-data-table :headers="headers" :items="listaModelo" class="elevation-1 table-motoristas"
                        :search="filtro" :sort-by="['dataInclusao']" :sort-desc="[true]" :items-per-page="20"
                        :footer-props="{
                    itemsPerPageAllText: 'Todos',
                    itemsPerPageText: 'Itens por página',
                }">
                        <template v-slot:[`item.numeroDocumento`]="{ item }">
                            {{ customFormatComputed.formatCPFCNPJNumber(item.numeroDocumento) }}
                        </template>
                        <template v-slot:[`item.ativo`]="{ item }">
                            {{ item.ativo ? "Ativo" : "Inativo" }}
                        </template>
                        <template v-slot:[`item.conta`]="{ item }">
                            {{ item.conta ? item.conta.numeroConta : "Sem CC" }}
                        </template>
                        <template v-slot:[`item.jurosMensal`]="{ item }">
                            {{ item.jurosMensal}} %
                        </template>
                        <template v-slot:[`item.limiteDisponivel`]="{ item }">
                            {{ customFormatComputed.formatarReais(item.limiteDisponivel) }}
                        </template>
                        <template v-slot:[`item.salario`]="{ item }">
                            {{ customFormatComputed.formatarReais(item.salario) }}
                        </template>
                        <template v-slot:[`item.dataInclusao`]="{ item }">
                            {{
                    customFormatComputed.toLocaleDateTimePtBrString(item.dataInclusao)
                }}
                        </template>
                        <template v-slot:[`item.controle`]="{ item }">
                            <v-layout justify-center>
                                <v-tooltip color="secondary" bottom>
                                    <template v-slot:activator="{ on }">
                                        <td>
                                            <v-btn small depressed fab v-on="on" color="secondary"
                                                class="ma-2 px-1 icon-edit" @click="go('SimulacoesFuncionario', {
                    id:
                        cipherComputed.encrypt(item.idFuncionario)
                })">
                                                <v-icon style="font-size: 16px">mdi-cash-sync</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <span>Ver Simulações do(a) {{ item.nome }}</span>
                                </v-tooltip>
                                <v-tooltip color="secondary" bottom>
                                    <template v-slot:activator="{ on }">
                                        <td>
                                            <v-btn small depressed fab v-on="on" color="secondary"
                                                class="ma-2 px-1 icon-edit" @click="go('PropostasFuncionario', {
                    id:
                        cipherComputed.encrypt(item.idFuncionario)
                })">
                                                <v-icon style="font-size: 16px">mdi-book-sync-outline</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <span>Ver Propostas do(a) {{ item.nome }}</span>
                                </v-tooltip>
                                <v-tooltip color="secondary" bottom>
                                    <template v-slot:activator="{ on }">
                                        <td>
                                            <v-btn small depressed fab v-on="on" color="secondary"
                                                class="ma-2 px-1 icon-edit" @click="go('CadastroFuncionario', {
                    id:
                        cipherComputed.encrypt(item.idFuncionario)
                })">
                                                <v-icon style="font-size: 16px">edit</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <span>Editar Funcionário(a) {{ item.nome }}</span>
                                </v-tooltip>
                            </v-layout>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar :timeout="3200" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
            v-model="snackbarModel.mostrar" bottom multi-line>
            {{ snackbarModel.text }}
            <v-btn dark text @click.native="snackbarModel.mostrar = false">Fechar</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";

export default {
    name: "Funcionarios",
    data: () => ({
        snackbarModel: {
            mostrar: false,
            sucesso: false,
            text: "",
        },
        filtro: "",
        headers: [
            { text: "Nome", value: "nome" },
            { text: "CPF/CNPJ", value: "numeroDocumento" },
            { text: "RG", value: "rg" },
            { text: "Dia de Pagamento", value: "diaPagamento" },
            { text: "Salário", value: "salario" },
            { text: "Limite Disp.", value: "limiteDisponivel" },
            { text: "Taxa Juros", value: "jurosMensal" },
            { text: "Conta Bancária", value: "conta" },
            { text: "Usuário", value: "usuario.nome" },
            { text: "Data Inclusão", value: "dataInclusao" },
            { text: "", value: "controle", sortable: false },
        ],
        listaModelo: [],
    }),
    mounted: function () {
        this.buscar()
        this.externalSnackbarMessage()
    },
    computed: {
        customFormatComputed: function () {
            return customFormat;
        },
        cipherComputed: function () {
            return cipher;
        },
    },
    methods: {
        buscar: function () {
            const self = this;
            if (
                this.$route.params.id &&
                Number.isInteger(parseInt(cipher.decrypt(this.$route.params.id)))
            ) {
                self.$http
                    .get(
                        `${process.env.VUE_APP_URL_API}/funcionario/busca/` +
                        cipher.decrypt(this.$route.params.id),
                        {
                            headers: {
                                Authorization:
                                    `Bearer ${localStorage.getItem("token")}`,
                            },
                        }
                    )
                    .then(function (response) {
                        self.listaModelo = response.data;
                    });
            } else {
                self.$store.commit("startLoading");
                self.$http
                    .get(`${process.env.VUE_APP_URL_API}/funcionario/busca`, {
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    })
                    .then(function (response) {
                        self.listaModelo = response.data;
                        self.$store.commit("endLoading");
                    }).catch(function (error) {
                        console.log(error.response.data)
                        self.$store.commit("endLoading")
                    });
            }
        },
        externalSnackbarMessage: function () {
            const self = this;
            if (self.$route.params.snackbarMostrar) {
                self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
                self.snackbarModel.text = self.$route.params.snackbarText;
                self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
            }
        },
        go: function (routeName, routeParams) {
            const self = this;
            if (!routeParams) {
                self.$router.push({ name: routeName });
            } else {
                self.$router.push({ name: routeName, params: routeParams });
            }
        }
    }
};
</script>