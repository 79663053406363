import jwt from '@/helpers/jwt.js'

export default {
  USUARIO_LOGADO: function () {
    if (jwt.decryptToken() != null) {
      return jwt.decryptToken(); /* atributos: email, exp, id, idtipousuario, nome, idconta */
    } else {
      return false;
    }
  },
  usuarioProprio: function (userIdParam) {
    return this.USUARIO_LOGADO().id === userIdParam;
  }
}
