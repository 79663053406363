<template>
  <v-container
    fluid
    fill-height
    :class="$vuetify.breakpoint.xsOnly ? 'container-menor' : ''"
  >
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12 default-border">
          <v-toolbar text>
            <v-toolbar-title>Bem-vindo!</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="formularioLoginValido" ref="formularioLoginRef">
              <v-text-field
                v-model="loginModel.email"
                color="secondary"
                :rules="regras.email"
                prepend-icon="person"
                label="E-mail"
                type="text"
                validate-on-blur
                @keyup.enter="fazerLogin"
                required
              ></v-text-field>
              <v-text-field
                v-model="loginModel.senha"
                color="secondary"
                :rules="regras.senha"
                id="senha"
                prepend-icon="lock"
                label="Senha"
                type="password"
                @keyup.enter="fazerLogin"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="goEsqueciSenha()"
              color="secondary"
              small
              class="default-border"
              >Esqueci minha senha</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              id="entrar"
              color="secondary"
              class="default-border"
              @click="fazerLogin"
              >Entrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar
      :timeout="3500"
      :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
      v-model="snackbarModel.mostrar"
      bottom
      multi-line
    >
      {{ snackbarModel.text }}
      <v-btn
        elevation="0"
        :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
        @click.native="snackbarModel.mostrar = false"
        >Fechar</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    formularioLoginValido: false,
    regras: {
      email: [
        (v) => !!v || "Você não pode deixar este campo em branco",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Você precisa informar um e-mail válido",
      ],
      senha: [(v) => !!v || "Você não pode deixar este campo em branco"],
    },
    loginModel: {
      email: "",
      senha: "",
    },
    snackbarModel: {
      mostrar: false,
      text: "",
      sucesso: false,
      visivel: false,
    }
  }),
  mounted: function () {
    this.externalSnackbarMessage();
  },
  methods: {
    fazerLogin: function () {
      const self = this;
      if (self.$refs.formularioLoginRef.validate()) {
        self.$http
          .post(
            `${process.env.VUE_APP_URL_API}/no-auth/authentication/login`,
            self.loginModel
          )
          .then(function (response) {
            const data = response.data;
            if (!data.sucesso) {
              self.snackbarModel.sucesso = false;
              self.snackbarModel.text = data.mensagem;
              self.snackbarModel.mostrar = true;
            } else {
              localStorage.setItem("token", data.accessToken);
              self.$router.push({ name: "Portal", params: { login: true } }); //Avisa o router que é uma ação de login.
            }
          })
          .catch(() => {
            self.snackbarModel.visivel = true;
            self.snackbarModel.mensagem =
              "Algo inesperado ocorreu na aplicação. Contate o administrador";
          });
      }
    },
    externalSnackbarMessage: function () {
      const self = this;
      if (self.$route.params.snackbarMostrar) {
        self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
        self.snackbarModel.text = self.$route.params.snackbarText;
        self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
      }
    },
    goEsqueciSenha: function () {
      this.$router.push("/EsqueciSenha");
    },
  },
};
</script>

<style scoped>
.container-menor {
  padding: 8px !important;
}
</style>
