<template>
  <v-container fluid grid-list-lg>
    <v-layout row wrap>
      <v-flex xs12 lg3>
        <v-card color="purple darken-2" class="white--text">
          <v-card-title primary-title>
            <div>
              <div class="headline">Saldo</div>
              <div v-if="!loadingSaldo">
                <span class="subheading mr-2">{{ customFormatComputed.formatarReais(dados.saldo) }}</span>
              </div>
              <div v-else>
                <v-progress-linear :indeterminate="loadingSaldo" color="yellow darken-2"></v-progress-linear>
              </div>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-btn text dark @click="buscarSaldo">Atualizar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 lg3>
        <v-card color="#26c6da" class="white--text">
          <v-card-title primary-title>
            <div>
              <div class="headline">Chave PIX</div>
              <span class="subtitle-1"><b>{{ dados.keypix }}</b></span>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-btn text dark @click="utilsComputed.copy(dados.keypix)">Copiar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 lg3>
        <v-card color="purple" class="white--text">
          <v-card-title primary-title>
            <div>
              <div class="headline">Funcionários</div>
              <span class="subtitle-1"><b>Número de funcionários no sistema: {{ dados.funcionarios }}</b> </span>
              <br />
            </div>
          </v-card-title>
          <v-card-actions>
            <v-btn text dark disabled></v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 lg3>
        <v-card color="blue-grey darken-1" class="white--text">
          <v-card-title primary-title>
            <div>
              <div class="headline">Propostas de Créditos</div>
              <div>
                <span class="subtitle-1"><b>Número de Propostas de Créditos: {{ dados.propostas }}</b></span>
              </div>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-btn text dark disabled></v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 lg3>
        <v-card color="cyan darken-2" class="white--text">
          <v-card-title primary-title>
            <div>
              <div class="headline">Crédito</div>
              <div>
                <span class="subtitle-1"><b>Numero de Simulações de Crédito: {{ dados.simulacoes }}</b></span>
              </div>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-btn text dark disabled></v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import utils from "@/helpers/utils.js";
import jwt from "@/helpers/jwt";
import customFormat from "@/helpers/custom-format.js";

export default {
  name: "Portal",
  data: () => ({
    loadingSaldo: true,
    dados: {
      keypix: "",
      saldo: 0.0,
      simulacoes: 0,
      propostas: 0,
      funcionarios: 0
    },
  }),
  mounted: function () {
    this.buscarKeypix();
    this.buscaCountFuncionarios();
    this.buscaCountPropostas();
    this.buscaCountSimulacoes();
    this.buscarSaldo();
  },
  computed: {
    customFormatComputed: function () {
      return customFormat;
    },
    utilsComputed: function () {
      return utils;
    }
  },
  methods: {
    buscarKeypix() {
      const self = this;

      const item = jwt.decryptToken();
      if (item != null) {
        self.$http
          .get(
            `${process.env.VUE_APP_URL_API}/portal/busca/keypix`,
            {
              headers: {
                Authorization:
                  `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(function (response) {
            self.dados.keypix = response.data;
          });
      }
      else {
        self.go("Login");
      }
    },
    buscaCountSimulacoes() {
      const self = this;

      const item = jwt.decryptToken();
      if (item != null) {
        self.$http
          .get(
            `${process.env.VUE_APP_URL_API}/portal/busca/count/simulacoes`,
            {
              headers: {
                Authorization:
                  `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(function (response) {
            self.dados.simulacoes = response.data
          });
      }
      else {
        self.go("Login");
      }
    },
    buscaCountPropostas() {
      const self = this;

      const item = jwt.decryptToken();
      if (item != null) {
        self.$http
          .get(
            `${process.env.VUE_APP_URL_API}/portal/busca/count/propostas`,
            {
              headers: {
                Authorization:
                  `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(function (response) {
            self.dados.propostas = response.data
          });
      }
      else {
        self.go("Login");
      }
    },
    buscaCountFuncionarios() {
      const self = this;

      const item = jwt.decryptToken();
      if (item != null) {
        self.$http
          .get(
            `${process.env.VUE_APP_URL_API}/portal/busca/count/funcionarios`,
            {
              headers: {
                Authorization:
                  `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(function (response) {
            self.dados.funcionarios = response.data
          });
      }
      else {
        self.go("Login");
      }
    },
    buscarSaldo() {
      const self = this;

      const item = jwt.decryptToken();
      if (item != null) {
        self.$http
          .get(
            `${process.env.VUE_APP_URL_API}/portal/busca/saldo`,
            {
              headers: {
                Authorization:
                  `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(function (response) {
            self.dados.saldo = response.data;
            self.loadingSaldo = false
          });
      }
      else {
        self.go("Login");
      }
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (this.$router.currentRoute.name != routeName) {
        if (!routeParams) {
          self.$router.push({ name: routeName });
        } else {
          self.$router.push({ name: routeName, params: routeParams });
        }
      }
    },
  },
};
</script>
