<template>
  <v-container>
    <v-layout align-center>
      <v-flex xs10>
        <h3 class="secondary--text">Empresas</h3>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 md4 text-xs-right>
        <v-text-field label="Pesquisar" v-model="filtro" color="secondary" append-icon="search"></v-text-field>
      </v-flex>
      <v-flex xs1 text-xs-right>
        <v-tooltip color="secondary" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" slot="activator" dark small color="secondary" @click="go('CadastroEmpresa')"
                   depressed>
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Adicionar uma nova Empresa</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <v-layout justify-space-around row wrap>
      <v-flex xs12>
        <v-card class="default-border" flat text>
          <v-data-table :headers="headers" :items="listaModelo" class="elevation-1 table-motoristas"
                        :search="filtro" :sort-by="['dataInclusao']" :sort-desc="[true]" :items-per-page="20"
                        :footer-props="{
                    itemsPerPageAllText: 'Todos',
                    itemsPerPageText: 'Itens por página',
                }">
            <template v-slot:[`item.contaPagamentoInterna`]="{ item }">
              {{ item.contaPagamentoInterna ? "Sim" : "Não" }}
            </template>
            <template v-slot:[`item.ativo`]="{ item }">
              {{ item.ativo ? "Ativo" : "Inativo" }}
            </template>
            <template v-slot:[`item.tacPadrao`]="{ item }">
              {{ customFormatComputed.formatarReais(item.tacPadrao) }}
            </template>
            <template v-slot:[`item.jurosMensalPadrao`]="{ item }">
              {{ item.jurosMensalPadrao }}%
            </template>
            <template v-slot:[`item.limiteMensalPadrao`]="{ item }">
              {{ item.limiteMensalPadrao }}%
            </template>

            <template v-slot:[`item.dataInclusao`]="{ item }">
              {{
                customFormatComputed.toLocaleDateTimePtBrString(item.dataInclusao)
              }}
            </template>
            <template v-slot:[`item.controle`]="{ item }">
              <v-layout justify-center>
                <v-tooltip color="secondary" bottom>
                  <template v-slot:activator="{ on }">
                    <td>
                      <v-btn small depressed fab v-on="on" color="secondary"
                             class="ma-2 px-1 icon-edit" @click="go('CadastroEmpresa', {
                    id:
                        cipherComputed.encrypt(item.idEmpresa)
                })">
                        <v-icon style="font-size: 16px">edit</v-icon>
                      </v-btn>
                    </td>
                  </template>
                  <span>Editar Empresa {{ item.nome }}</span>
                </v-tooltip>
                <v-tooltip color="secondary" bottom>
                  <template v-slot:activator="{ on }">
                    <td>
                      <v-btn small depressed fab v-on="on" color="secondary"
                             class="ma-2 px-1 icon-edit" @click="go('CadastroSplit', {
                    id:
                        cipherComputed.encrypt(item.numeroConta)
                })" :disabled="item.contaPagamentoInterna">
                        <v-icon style="font-size: 16px">mdi-call-split</v-icon>
                      </v-btn>
                    </td>
                  </template>
                  <span>Split para Empresa {{ item.nome }}</span>
                </v-tooltip>
              </v-layout>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar :timeout="3200" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
                v-model="snackbarModel.mostrar" bottom multi-line>
      {{ snackbarModel.text }}
      <v-btn dark text @click.native="snackbarModel.mostrar = false">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";

export default {
  name: "Empresas",
  data: () => ({
    snackbarModel: {
      mostrar: false,
      sucesso: false,
      text: "",
    },
    filtro: "",
    headers: [
      {text: "ID", value: "idEmpresa"},
      {text: "Apelido", value: "apelido"},
      {text: "Nome", value: "nome"},
      {text: "Empresa CC Interna", value: "contaPagamentoInterna"},
      {text: "Conta", value: "numeroConta"},
      {text: "Account ID", value: "idConta"},
      {text: "Chave PIX", value: "chavePIX"},
      {text: "Juros a.m.", value: "jurosMensalPadrao"},
      {text: "Limite Mensal", value: "limiteMensalPadrao"},
      {text: "TAC", value: "tacPadrao"},
      {text: "Cod. Abertura", value: "openingAccountId"},
      {text: "Status Conta", value: "tipoStatusAberturaConta.nomeCartos"},
      {text: "Status", value: "ativo"},
      {text: "Data Inclusão", value: "dataInclusao"},
      {text: "", value: "controle", sortable: false},
    ],
    listaModelo: [],
  }),
  mounted: function () {
    this.buscar()
    this.externalSnackbarMessage()
  },
  computed: {
    customFormatComputed: function () {
      return customFormat;
    },
    cipherComputed: function () {
      return cipher;
    },
  },
  methods: {
    buscar: function () {
      const self = this;
      if (
        this.$route.params.id &&
        Number.isInteger(parseInt(cipher.decrypt(this.$route.params.id)))
      ) {
        self.$http
          .get(
            `${process.env.VUE_APP_URL_API}/empresa/busca/` +
            cipher.decrypt(this.$route.params.id),
            {
              headers: {
                Authorization:
                  `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(function (response) {
            self.listaModelo = response.data;
          });
      } else {
        self.$store.commit("startLoading");
        self.$http
          .get(`${process.env.VUE_APP_URL_API}/empresa/busca/`, {
            headers: {
              Authorization:
                `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then(function (response) {
            self.listaModelo = response.data;
            self.$store.commit("endLoading");
          }).catch(function (error) {
          console.log(error.response.data)
          self.$store.commit("endLoading")
        });
      }
    },
    externalSnackbarMessage: function () {
      const self = this;
      if (self.$route.params.snackbarMostrar) {
        self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
        self.snackbarModel.text = self.$route.params.snackbarText;
        self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
      }
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (!routeParams) {
        self.$router.push({name: routeName});
      } else {
        self.$router.push({name: routeName, params: routeParams});
      }
    }
  }
};
</script>
