const Utils = {
    linkWhatsapp: function (nome, telefone, texto) {
        var newstr = texto.replace('$', nome);
        window.open(
            "https://api.whatsapp.com/send?phone=+55" +
            telefone +
            "&text=" + newstr);
    },
    async copy(qrcode) {
        try {
            await navigator.clipboard.writeText(qrcode);
        } catch ($e) {
            console.log($e);
        }
    },
    gotoLinkNewTab(url) {
        window.open(url, '​_blank​');
    },
    isUUID: function (uuid) {
        let s = "" + uuid;

        s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
        if (s === null) {
            return false;
        }
        return true;
    }
}
export default Utils