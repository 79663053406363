var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{attrs:{"xs10":""}},[_c('h3',{staticClass:"secondary--text"},[_vm._v("Contas")])]),_c('v-spacer'),_c('v-flex',{attrs:{"xs12":"","md4":"","text-xs-right":""}},[_c('v-text-field',{attrs:{"label":"Pesquisar","color":"secondary","append-icon":"search"},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1)],1),_c('v-layout',{attrs:{"justify-space-around":"","row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"default-border",attrs:{"flat":"","text":""}},[_c('v-data-table',{staticClass:"elevation-1 table-motoristas",attrs:{"headers":_vm.headers,"items":_vm.listaModelo,"search":_vm.filtro,"sort-by":['dataInclusao'],"sort-desc":[true],"items-per-page":20,"footer-props":{
                itemsPerPageAllText: 'Todos',
                itemsPerPageText: 'Itens por página',
            }},scopedSlots:_vm._u([{key:"item.empresa",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(!item.empresa ? "Sem Vinculo" : item.empresa.apelido)+" ")]}},{key:"item.numeroDocumento",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.customFormatComputed.formatCPFCNPJNumber(item.numeroDocumento))+" ")]}},{key:"item.ativo",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.ativo ? "Ativo" : "Inativo")+" ")]}},{key:"item.dataInclusao",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.customFormatComputed.toLocaleDateTimePtBrString(item.dataInclusao))+" ")]}}],null,true)})],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3200,"color":("" + (_vm.snackbarModel.sucesso ? 'success' : 'error')),"bottom":"","multi-line":""},model:{value:(_vm.snackbarModel.mostrar),callback:function ($$v) {_vm.$set(_vm.snackbarModel, "mostrar", $$v)},expression:"snackbarModel.mostrar"}},[_vm._v(" "+_vm._s(_vm.snackbarModel.text)+" "),_c('v-btn',{attrs:{"dark":"","text":""},nativeOn:{"click":function($event){_vm.snackbarModel.mostrar = false}}},[_vm._v("Fechar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }