import jwt from 'jsonwebtoken';

export default {
  decryptToken: function () {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        return jwt.verify(token, `${process.env.VUE_APP_TOKEN_SECRET}`);
      } catch (err) {
        return null;
      }
    } else {
      return null;
    }
  }
}
