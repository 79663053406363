<template>
  <v-container
    fluid
    fill-height
    :class="$vuetify.breakpoint.xsOnly ? 'container-menor' : ''"
  >
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm6 md4>
        <v-card v-if="!tokenVerificado" class="default-border">
          <v-toolbar text>
            <v-toolbar-title class="white--text"
              >Recuperação/Nova senha</v-toolbar-title
            >
          </v-toolbar>
          <v-card-text>
            <v-container fill-height>
              <v-layout column justify-center align-center>
                <v-progress-circular
                  indeterminate
                  :size="70"
                  :width="6"
                  color="grey"
                >
                  <v-layout flex align-center justify-center>
                    <v-flex xs4>
                      <v-icon medium color="grey">locker</v-icon>
                    </v-flex>
                  </v-layout>
                </v-progress-circular>
                <h4 class="themeGrey--text">Verificando autenticidade</h4>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card v-else class="default-border">
          <v-toolbar text>
            <v-toolbar-title class="white--text"
              >Recuperação de senha</v-toolbar-title
            >
          </v-toolbar>
          <v-card-text>
            <v-form ref="esqueciSenhaForm" v-model="esqueciSenhaFormValid">
              <v-container grid-list-lg>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      label="Senha"
                      id="campoSenha"
                      v-model="esqueciSenha.senha"
                      :rules="senhaRule"
                      :type="!verSenha ? 'password' : 'text'"
                      :append-icon="!verSenha ? 'visibility' : 'visibility_off'"
                      @click:append="() => (verSenha = !verSenha)"
                      @keydown.enter.prevent
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      label="Repita a senha"
                      id="campoRepitaSenha"
                      v-model="esqueciSenha.repitaSenha"
                      :rules="confirmacaoSenhaRule"
                      :type="!verRepitaSenha ? 'password' : 'text'"
                      :append-icon="
                        !verRepitaSenha ? 'visibility' : 'visibility_off'
                      "
                      @click:append="() => (verRepitaSenha = !verRepitaSenha)"
                      @keydown.enter.prevent
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click.native="alterarSenha"
              id="cadastrarSenhaBtn"
              :loading="emProcessamento"
              :disabled="
                !esqueciSenhaFormValid || snackbar.sucesso || emProcessamento
              "
              class="default-border"
              color="primary"
              depressed
              >Cadastrar</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-dialog v-model="modalErro" max-width="500px">
          <v-card>
            <v-card-text>
              <v-container fill-height>
                <v-layout column justify-center align-center>
                  <v-flex xs12>
                    <v-icon class="display-3" color="error">error</v-icon>
                  </v-flex>
                  <h4 class="error--text">{{ modalErroMensagem }}</h4>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-snackbar
          :timeout="6000"
          :color="snackbar.sucesso ? 'success' : 'error'"
          multi-line
          v-model="snackbar.visivel"
        >
          {{ snackbar.mensagem }}
          <v-btn dark text @click.native="snackbar.visivel = false"
            >Fechar</v-btn
          >
        </v-snackbar>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "RecuperarSenha",
  data: () => {
    return {
      emProcessamento: false,
      esqueciSenhaFormValid: false,
      tokenVerificado: false,
      modalErro: false,
      modalErroMensagem: "",
      snackbar: {
        sucesso: false,
        visivel: false,
        mensagem: "",
      },
      verSenha: false,
      verRepitaSenha: false,
      esqueciSenha: {
        senha: "",
        repitaSenha: "",
      },
      regras: {
        senha: [
          (v) => !!v || "Você não pode deixar este campo em branco",
          (v) => v.length >= 8 || "A senha deve ter 8 ou mais caracteres",
        ],
        repitaSenha: [
          (v) => !!v || "Você não pode deixar este campo em branco",
          (v) => v.length >= 8 || "A senha deve ter 8 ou mais caracteres",
        ],
      },
    };
  },
  computed: {
    senhaRule: function () {
      return [
        (this.esqueciSenha.senha != undefined &&
          this.esqueciSenha.senha != null &&
          this.esqueciSenha.senha != "") ||
          "Você não pode deixar este campo em branco",
        this.esqueciSenha.senha.length >= 8 ||
          "A senha deve ter no mínimo 8 caracteres",
      ];
    },
    confirmacaoSenhaRule: function () {
      return [
        this.esqueciSenha.senha == this.esqueciSenha.repitaSenha ||
          "Confirme corretamente a sua senha",
      ];
    },
  },
  watch: {
    modalErro: function (newValue, oldValue) {
      if (oldValue && !newValue) {
        this.$router.push("/");
      }
    },
  },
  mounted: function () {
    this.verificaToken();
  },
  methods: {
    verificaToken: function () {
      const self = this;
      setTimeout(function () {
        //Para causar sensação de segurança
        axios
          .get(
            `${process.env.VUE_APP_URL_API}/no-auth/authentication/verifica-token-valido?token=${self.$route.params.token}`
          )
          .then((response) => {
            if (response.data) {
              self.tokenVerificado = true;
            } else {
              self.modalErro = true;
              self.modalErroMensagem = "Não é permitido realizar esta ação";
            }
          })
          .catch(() => {
            self.modalErro = true;
            self.modalErroMensagem = "Não é permitido realizar esta ação";
          });
      }, 3000);
    },
    verificaSenhas: function () {
      const self = this;
      if (self.esqueciSenha.senha != self.esqueciSenha.repitaSenha) {
        self.snackbar.sucesso = false;
        self.snackbar.visivel = true;
        self.snackbar.mensagem = "As senhas não combinam";
        return false;
      }
      return true;
    },
    alterarSenha: function () {
      const self = this;
      self.emProcessamento = true;
      if (self.verificaSenhas()) {
        axios
          .post(
            `${process.env.VUE_APP_URL_API}/no-auth/authentication/alterar-senha-por-token-link`,
            {
              token: self.$route.params.token,
              senha: self.esqueciSenha.senha,
              confirmacao: self.esqueciSenha.repitaSenha,
            }
          )
          .then((response) => {
            if (response.data) {
              self.go("Login", {
                snackbarText: "Senha alterada com sucesso!",
                snackbarMostrar: true,
                snackbarSucesso: true,
              });
            } else {
              self.emProcessamento = false;
              self.modalErro = true;
              self.modalErroMensagem = "Erro na redefinição de senha";
            }
          })
          .catch(() => {
            self.modalErro = true;
            self.modalErroMensagem = "Não foi possível realizar esta ação";
          });
      }
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (!routeParams) {
        self.$router.push({ name: routeName });
      } else {
        self.$router.push({ name: routeName, params: routeParams });
      }
    },
  },
};
</script>

<style scoped>
.container-menor {
  padding: 8px !important;
}
</style>
