<template>
    <v-container>
        <v-layout align-center>
            <v-flex xs10>
                <h3 class="secondary--text">Simulações</h3>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 md4 text-xs-right>
                <v-text-field label="Pesquisar" v-model="filtro" color="secondary" append-icon="search"></v-text-field>
            </v-flex>
            <v-flex xs1 text-xs-right>
                <v-tooltip color="secondary" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" slot="activator" dark small color="secondary"
                            @click="go('CadastroFuncionario')" depressed :disabled="true">
                            <v-icon>add</v-icon>
                        </v-btn>
                    </template>
                    <span>Adicionar nova Simulação</span>
                </v-tooltip>
            </v-flex>
            <v-flex xs1 text-xs-right>
                <v-tooltip color="secondary" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" slot="activator" dark small color="secondary" @click="buscar" depressed>
                            <v-icon>mdi-sync</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar Dados</span>
                </v-tooltip>
            </v-flex>
        </v-layout>
        <v-layout justify-space-around row wrap>
            <v-flex xs12>
                <v-card class="default-border" flat text>
                    <v-card-text>
                        <v-row align="center" class="mx-0">
                            <v-flex xs12 md6>Nome: {{ model.nome }}</v-flex>
                            <v-flex xs12 md6>CPF/CNPJ: {{ customFormatComputed.formatCPFCNPJNumber(model.numeroDocumento)
                                }}</v-flex>
                            <v-flex xs12 md6>Email: {{ model.email }}</v-flex>
                            <v-flex xs12 md6>Salário: {{ customFormatComputed.formatarReais(model.salario)
                                }}</v-flex>
                            <v-flex xs12 md6>Limite Disponível: {{
                    customFormatComputed.formatarReais(model.limiteDisponivel) }}</v-flex>
                            <v-flex xs12 md6>Juros Mensal Padrão: {{ model.jurosMensal }} %</v-flex>
                            <v-flex xs12 md6>Conta: {{ model.conta ? model.conta.numeroConta : "Sem CC" }}</v-flex>
                            <v-flex xs12 md6>Data Ultima Atualização: {{
                    customFormatComputed.toLocaleDateTimePtBrString(model.dataAtualizacao) }}</v-flex>
                            <v-flex xs12 md6>Data Inclusão: {{
                    customFormatComputed.toLocaleDateTimePtBrString(model.dataInclusao)
                }}</v-flex>
                            <v-flex xs12 md6>Status: {{ model.ativo ? "Ativo" : "Inativo" }}</v-flex>
                            <v-flex xs12 md6>Usuario Criador: {{ model.usuario.nome }}</v-flex>
                        </v-row></v-card-text>
                </v-card>
                <v-card class="default-border" flat text>
                    <v-data-table :headers="headers" :items="listaModelo" class="elevation-1 table-motoristas"
                        :search="filtro" :sort-by="['dataInclusao']" :sort-desc="[true]" :items-per-page="20"
                        :footer-props="{
                    itemsPerPageAllText: 'Todos',
                    itemsPerPageText: 'Itens por página',
                }">
                        <template v-slot:[`item.valor`]="{ item }">
                            {{ "R$ " + customFormatComputed.parseNumber(item.valor / 100) }}
                        </template>
                        <template v-slot:[`item.valorTAC`]="{ item }">
                            {{ "R$ " + customFormatComputed.parseNumber(item.valorTAC / 100) }}
                        </template>
                        <template v-slot:[`item.valorIOF`]="{ item }">
                            {{ "R$ " + customFormatComputed.parseNumber(item.valorIOF / 100) }}
                        </template>
                        <template v-slot:[`item.valorJuros`]="{ item }">
                            {{ "R$ " + customFormatComputed.parseNumber(item.valorJuros / 100) }}
                        </template>
                        <template v-slot:[`item.valorLiquido`]="{ item }">
                            {{ "R$ " + customFormatComputed.parseNumber(item.valorLiquido / 100) }}
                        </template>
                        <template v-slot:[`item.jurosMesalPercentual`]="{ item }">
                            {{ item.jurosMesalPercentual + ' %' }}
                        </template>
                        <template v-slot:[`item.jurosTotalPercentual`]="{ item }">
                            {{ item.jurosTotalPercentual + ' %' }}
                        </template>
                        <template v-slot:[`item.valorCETPercentual`]="{ item }">
                            {{ item.valorCETPercentual + ' %' }}
                        </template>
                        <template v-slot:[`item.dataInclusao`]="{ item }">
                            {{
                    customFormatComputed.toLocaleDateTimePtBrString(item.dataInclusao)
                }}
                        </template>
                        <!-- <template v-slot:[`item.controle`]="{ item }">
                            <v-layout justify-center>
                                <v-tooltip color="secondary" bottom>
                                    <template v-slot:activator="{ on }">
                                        <td>
                                            <v-btn small depressed fab v-on="on" color="secondary"
                                                class="ma-2 px-1 icon-edit" @click="go('CadastroFuncionario', {
                    id:
                        cipherComputed.encrypt(item.idFuncionario)
                })">
                                                <v-icon style="font-size: 16px">mdi-cash-sync</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <span>Editar Funcionario {{ item.nome }}</span>
                                </v-tooltip>
                            </v-layout>
                        </template> -->

                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar :timeout="3200" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
            v-model="snackbarModel.mostrar" bottom multi-line>
            {{ snackbarModel.text }}
            <v-btn dark text @click.native="snackbarModel.mostrar = false">Fechar</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";

export default {
    name: "SimulacoesFuncionario",
    data: () => ({
        snackbarModel: {
            mostrar: false,
            sucesso: false,
            text: "",
        },
        model: {
            nome: "",
            numeroDocumento: "",
            email: "",
            salario: 0,
            limiteDisponivel: 0,
            jurosMensal: 0,
            ativo: false,
            conta: { numeroConta: "", },
            usuario: { nome: "" },
            dataAtualizacao: null,
            dataInclusao: null
        },
        filtro: "",
        headers: [
            { text: "Cod. Simulação", value: "idCodigoSimulacao" },
            { text: "Valor", value: "valor" },
            { text: "TAC", value: "valorTAC" },
            { text: "IOF", value: "valorIOF" },
            { text: "Juros", value: "valorJuros" },
            { text: "Líquido", value: "valorLiquido" },
            { text: "Juros Mesal %", value: "jurosMesalPercentual" },
            { text: "Juros %", value: "jurosTotalPercentual" },
            { text: "CET %", value: "valorCETPercentual" },
            { text: "Data Inclusão", value: "dataInclusao" },
            { text: "", value: "controle", sortable: false },
        ],
        listaModelo: [],
    }),
    mounted: function () {
        this.buscar()
        this.externalSnackbarMessage()
    },
    computed: {
        customFormatComputed: function () {
            return customFormat;
        },
        cipherComputed: function () {
            return cipher;
        },
    },
    methods: {
        buscar() {
            const self = this;
            self.$store.commit("startLoading");
            if (
                this.$route.params.id &&
                Number.isInteger(parseInt(cipher.decrypt(this.$route.params.id)))
            ) {
                self.$http
                    .get(
                        `${process.env.VUE_APP_URL_API}/simulacao-credito/busca/` +
                        cipher.decrypt(this.$route.params.id),
                        {
                            headers: {
                                Authorization:
                                    `Bearer ${localStorage.getItem("token")}`,
                            },
                        }
                    )
                    .then(function (response) {
                        self.listaModelo = response.data;
                        self.$store.commit("endLoading")
                    }).catch(function (error) {
                        self.$store.commit("endLoading");
                        console.log(error);
                    });
                self.$http
                    .get(
                        `${process.env.VUE_APP_URL_API}/funcionario/busca/` +
                        cipher.decrypt(this.$route.params.id),
                        {
                            headers: {
                                Authorization:
                                    `Bearer ${localStorage.getItem("token")}`,
                            },
                        }
                    )
                    .then(function (response) {
                        self.model = response.data[0];
                        self.$store.commit("endLoading")
                    }).catch(function (error) {
                        self.$store.commit("endLoading");
                        console.log(error);
                    });
            }
            else {
                self.go("Funcionarios");
            }
        },
        externalSnackbarMessage: function () {
            const self = this;
            if (self.$route.params.snackbarMostrar) {
                self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
                self.snackbarModel.text = self.$route.params.snackbarText;
                self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
            }
        },
        go: function (routeName, routeParams) {
            const self = this;
            if (!routeParams) {
                self.$router.push({ name: routeName });
            } else {
                self.$router.push({ name: routeName, params: routeParams });
            }
        }
    }
};
</script>