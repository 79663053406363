<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm8>
        <v-card class="elevation-12 default-border">
          <v-toolbar color="secondary">
            <v-toolbar-title>{{
              this.$route.params.id
                ? "Edição de Funcionário " + cadastroModel.nome
                : "Cadastro de Funcionário"
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="formularioCadastroValido" ref="formularioCadastroRef">
              <v-container grid-list-lg>
                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.nome" color="secondary" :rules="regras.branco"
                      prepend-icon="person" label="Nome Completo" type="text" required></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="formattedNumeroDocumento" color="secondary" :rules="regrasDocumento"
                      prepend-icon="mdi-card-account-details-outline" label="CPF/CNPJ" type="text" required
                      @input="updateNumeroDocumento"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.rg" color="secondary" :rules="regras.branco"
                      prepend-icon="mdi-card-account-details-outline" label="RG" type="text" required></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-select v-model="cadastroModel.tipoEstadoCivil" color="secondary" :rules="regras.braco"
                      :items="listaTipoEstadoCivil" item-text="nome" prepend-icon="account_balance" label="Estado Civil"
                      required return-object></v-select>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.email" color="secondary" :rules="regras.email"
                      prepend-icon="mail" label="Email" type="text" required></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-menu ref="menuCalendarDataNasc" v-model="menuCalendarDataNasc" :close-on-content-click="false"
                      :return-value.sync="cadastroModel.dataNascimento" :nudge-right="40" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dataNascFormatada" label="Data de Nascimento" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" :rules="regras.branco"></v-text-field>
                      </template>
                      <v-date-picker ref="picker" :active-picker.sync="activePickerNasc"
                        v-model="cadastroModel.dataNascimento" locale="pt-br" :min="new Date(
              new Date().setFullYear(new Date().getFullYear() - 100)
            )
              .toISOString()
              .substring(0, 10)" :max="new Date(
              new Date().setFullYear(new Date().getFullYear() - 16)
            )
              .toISOString()
              .substring(0, 10)" required @input="
              $refs.menuCalendarDataNasc.save(
                cadastroModel.dataNascimento
              )
              "></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field ref="txtsalarioFormatado" v-model="salarioFormatado" color="secondary"
                      :rules="regras.branco" prepend-icon="mdi-currency-usd" label="Salário" type="text" required
                      v-money="money"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.diaPagamento" color="secondary" :rules="regrasDiaPagamento"
                      prepend-icon="mdi-account-arrow-down-outline" label="Dia de Pagamento" type="number"
                      required></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field ref="txtlimiteDisponivelFormatado" v-model="limiteDisponivelFormatado"
                      color="secondary" :rules="regras.branco" prepend-icon="mdi-currency-usd"
                      label="Limite Disponível de Adiantamento" type="text" required v-money="money"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.jurosMensal" color="secondary" :rules="regras.branco"
                      prepend-icon="mdi-percent-outline" label="Juros Mesal" type="text" required></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-switch :label="`${cadastroModel.ativo ? 'Ativo' : 'Inativo'}`" v-model="cadastroModel.ativo"
                      color="primary"></v-switch>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-select v-model="cadastroModel.empresa" color="secondary" :rules="regras.braco"
                      :items="listaEmpresas" item-text="nome" prepend-icon="account_balance" label="Empresas" required
                      return-object></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn id="voltar" color="primary" class="grey--text text--darken-1" :to="{ name: 'Funcionarios' }"
              text>Voltar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="finalizar" color="secondary" class="default-border" depressed @click="finalizar()"
              :disabled="disableBtnFinalizar || !formValid">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar :timeout="5000" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`" bottom multi-line
      v-model="snackbarModel.mostrar">
      {{ snackbarModel.text }}
      <v-btn text dark @click.native="
              `${snackbarModel.sucesso
                ? go('Funcionarios')
                : (snackbarModel.mostrar = false)
              }`
              ">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import customFormat from "@/helpers/custom-format.js";
import cipher from "@/helpers/cipher.js";
import perfil from "@/helpers/perfil.js";
import jwt from "@/helpers/jwt";
import { VMoney } from 'v-money'

export default {
  name: "CadastroFuncionario",
  data: () => ({
    menuCalendarDataNasc: false,
    dataNascFormatada: null,
    activePickerNasc: null,
    formularioCadastroValido: false,
    disableBtnFinalizar: false,
    disableBtnBuscar: false,
    salarioFormatado: 0.0,
    limiteDisponivelFormatado: 0.0,
    listaEmpresas: [],
    listaTipoEstadoCivil: [
      { idTipoEstadoCivil: 1, nome: 'Solteiro' },
      { idTipoEstadoCivil: 2, nome: 'Casado' },
      { idTipoEstadoCivil: 3, nome: 'Separado' },
      { idTipoEstadoCivil: 4, nome: 'Divorciado' },
      { idTipoEstadoCivil: 5, nome: 'Viúvo' }
    ],
    cadastroModel: {
      idFuncionario: null,
      nome: null,
      email: null,
      numeroDocumento: null,
      rg: null,
      tipoEstadoCivil: null,
      salario: null,
      limiteDisponivel: null,
      diaPagamento: null,
      jurosMensal: null,
      ativo: true,
      empresa: null,
      dataNascimento: null
    },
    snackbarModel: {
      mostrar: false,
      text: "",
      sucesso: false,
    },
    regras: {
      branco: [(v) => !!v || "Você não pode deixar este campo em branco"],
      email: [
        (v) => !!v || "Você não pode deixar este campo em branco",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Você precisa informar um e-mail válido",
      ],
    },
    regrasDocumento: [],
    regrasDiaPagamento: [],
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
      masked: false
    },
  }),
  directives: { money: VMoney },
  created() {
    this.regrasDocumento = [
      v => !!v || 'Campo obrigatório',
      v => this.validaDocumento(v) || 'CPF/CNPJ inválido',
    ];
    this.regrasDiaPagamento = [
      v => !!v || 'Campo obrigatório', // Verifica se o campo está preenchido
      v => this.validaDiaPagamento(v) || 'Dia de pagamento inválido', // Valida o valor inserido
    ];
  },
  computed: {
    formValid() {
      return this.formularioCadastroValido;
    },
    customFormatComputed() {
      return customFormat;
    },
    perfilUsr() {
      return perfil;
    },
    formattedNumeroDocumento: {
      get() {

        if (!this.cadastroModel.numeroDocumento) return '';
        const numeroDocumento = this.cadastroModel.numeroDocumento.replace(/\D/g, '');
        if (numeroDocumento.length <= 11) {
          // CPF
          return numeroDocumento
            .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
            .slice(0, 14);
        } else {
          // CNPJ
          return numeroDocumento
            .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
            .slice(0, 18);
        }
      },
      set(value) {
        this.cadastroModel.numeroDocumento = value.replace(/\D/g, '');
      },
    },
  },
  watch: {
    "cadastroModel.dataNascimento": function (val) {
      this.dataNascFormatada = val
        ? new Date(val).toLocaleDateString("pt-br", { timeZone: "UTC" })
        : "";
    },
    menuCalendarDataNasc(val) {
      val && setTimeout(() => (this.activePickerNasc = 'YEAR'))
    },
    salarioFormatado: function (val) {
      this.cadastroModel.salario = customFormat.removeMoneyFormat(val);
    },
    limiteDisponivelFormatado: function (val) {
      this.cadastroModel.limiteDisponivel = customFormat.removeMoneyFormat(val);
    },
  },
  mounted: function () {
    this.buscar();
    this.buscarEmpresas();
  },
  methods: {
    validaDiaPagamento(value) {
      const dia = parseInt(value, 10);
      return dia >= 1 && dia <= 31;
    },
    validaDocumento(value) {
      const numeroDocumento = value ? value.replace(/\D/g, '') : '';
      return numeroDocumento.length === 11 || numeroDocumento.length === 14;
    },
    updateNumeroDocumento() {
      this.cadastroModel.numeroDocumento = this.formattedNumeroDocumento.replace(/\D/g, '');
    },
    buscar() {
      const self = this;
      const item = jwt.decryptToken();
      if (item != null) {
        if (
          this.$route.params.id &&
          cipher.decrypt(this.$route.params.id)
        ) {
          self.$store.commit("startLoading");
          self.$http
            .get(
              `${process.env.VUE_APP_URL_API}/funcionario/busca/` +
              cipher.decrypt(this.$route.params.id),
              {
                headers: {
                  Authorization:
                    `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then(function (response) {
              self.cadastroModel = response.data[0];

              self.$refs.txtsalarioFormatado.$el.getElementsByTagName(
                "input"
              )[0].value = ("R$ " + response.data[0].salario).replace(".", ",");

              self.$refs.txtlimiteDisponivelFormatado.$el.getElementsByTagName(
                "input"
              )[0].value = ("R$ " + response.data[0].limiteDisponivel).replace(".", ",");

              self.$store.commit("endLoading");
            }).catch(function (error) {
              self.$store.commit("endLoading");
              self.snackbarModel.text = error.response.data.mensagem;
              self.snackbarModel.sucesso = false;
              self.snackbarModel.mostrar = true;
              self.disableBtnFinalizar = false;
              console.log(error);
            });
        }
      }
    },
    buscarEmpresas: function () {
      const self = this;
      const item = jwt.decryptToken();
      if (item != null) {
        if (item.idtipousuario == self.perfilUsr.ID.administrador) {
          self.$http
            .get(`${process.env.VUE_APP_URL_API}/empresa/busca/ativo`, {
              headers: {
                Authorization:
                  `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then(function (response) {
              self.listaEmpresas = response.data;
              self.listaEmpresas.sort(customFormat.compareNome);
            });
        }
        else {
          self.$http
            .get(
              `${process.env.VUE_APP_URL_API}/usuario/busca/` +
              item.id,
              {
                headers: {
                  Authorization:
                    `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then(function (response) {
              const resposta = response.data[0].empresa;
              self.cadastroModel.empresa = resposta;
              self.listaEmpresas.push(resposta);
            });
        }
      } else {
        self.go("Login");
      }
    },
    finalizar: function () {
      const self = this;
      self.$store.commit("startLoading");
      if (self.$refs.formularioCadastroRef.validate()) {
        const item = jwt.decryptToken();
        if (item != null) {
          if (!self.$route.params.id) {
            self.$http
              .post(
                `${process.env.VUE_APP_URL_API}/funcionario/cadastro`,
                self.cadastroModel,
                {
                  headers: {
                    Authorization:
                      `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then(function (response) {
                self.$store.commit("endLoading");
                const data = response.data;
                if (data.sucesso) {
                  self.disableBtnFinalizar = data.sucesso;

                  self.go("Funcionarios", {
                    snackbarText: data.mensagem,
                    snackbarMostrar: true,
                    snackbarSucesso: data.sucesso,
                  });

                } else {
                  self.snackbarModel.text = data.mensagem;
                  self.snackbarModel.mostrar = true;
                  self.snackbarModel.sucesso = data.sucesso;
                  self.disableBtnFinalizar = data.sucesso;
                }
              })
              .catch(function (error) {
                self.$store.commit("endLoading");
                self.snackbarModel.text = error.response.data.mensagem;
                self.snackbarModel.sucesso = false;
                self.snackbarModel.mostrar = true;
                self.disableBtnFinalizar = false;
              });
          } else {
            self.cadastroModel.idCartao = cipher.decrypt(
              self.$route.params.id
            );
            self.$http
              .post(
                `${process.env.VUE_APP_URL_API}/funcionario/edicao`,
                self.cadastroModel,
                {
                  headers: {
                    Authorization:
                      `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then(function (response) {
                self.$store.commit("endLoading");
                const data = response.data;
                if (data.sucesso) {
                  self.disableBtnFinalizar = data.sucesso;
                  self.go("Funcionarios", {
                    snackbarText: data.mensagem,
                    snackbarMostrar: true,
                    snackbarSucesso: data.sucesso,
                  });
                } else {
                  self.snackbarModel.text = data.mensagem;
                  self.snackbarModel.mostrar = true;
                  self.snackbarModel.sucesso = data.sucesso;
                  self.disableBtnFinalizar = data.sucesso;
                }
              })
              .catch(function (error) {
                self.$store.commit("endLoading");
                self.snackbarModel.text = error.response.data.mensagem;
                self.snackbarModel.sucesso = false;
                self.snackbarModel.mostrar = true;
                self.disableBtnFinalizar = false;
              });
          }
        } else {
          self.go("Login");
        }
      }
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (this.$router.currentRoute.name != routeName) {
        if (!routeParams) {
          self.$router.push({ name: routeName });
        } else {
          self.$router.push({ name: routeName, params: routeParams });
        }
      }
    },
  },
};
</script>