<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm8>
        <v-card class="elevation-12 default-border">
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{
              this.$route.params.id
                ? "Edição de usuário"
                : "Cadastro de usuário"
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="formularioCadastroValido" ref="formularioCadastroRef">
              <v-container grid-list-lg>
                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.nome" color="secondary" :rules="regras.nome"
                      prepend-icon="person" label="Nome" type="text" required></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.email" color="secondary" :rules="regras.email"
                      prepend-icon="mail" label="E-mail" type="text" required></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-select v-model="cadastroModel.tipoUsuario" color="secondary" :rules="regras.nome"
                      :items="listaTipoUsuario" item-text="nome" prepend-icon="supervised_user_circle"
                      label="Tipo Usuário" required return-object></v-select>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-select v-model="cadastroModel.empresa" color="secondary" :rules="regras.braco"
                      :items="listaEmpresas" item-value="idEmpresa" item-text="nome" prepend-icon="account_balance"
                      label="Empresas" required persistent-hint :hint="`Apelido: ${cadastroModel.empresa.apelido}`"
                      return-object></v-select>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-switch :label="`${cadastroModel.ativo ? 'Ativo' : 'Inativo'}`" v-model="cadastroModel.ativo"
                      color="primary"></v-switch>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn id="voltar" color="primary" class="grey--text text--darken-1" :to="{ name: 'Usuarios' }"
              text>Voltar</v-btn>
            <v-spacer></v-spacer>
            <v-btn id="finalizar" color="primary" class="default-border" depressed @click="finalizar"
              :disabled="disableBtnFinalizar || !formValid">Finalizar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar :timeout="5000" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`" bottom multi-line
      v-model="snackbarModel.mostrar">
      {{ snackbarModel.text }}
      <v-btn text dark @click.native="
              `${snackbarModel.sucesso
                ? go('Usuarios')
                : (snackbarModel.mostrar = false)
              }`
              ">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";
import jwt from "@/helpers/jwt";
import perfil from "@/helpers/perfil.js";

export default {
  name: "CadastroUsuario",
  components: {},
  data: () => ({
    formularioCadastroValido: false,
    regras: {
      email: [
        (v) => !!v || "Você não pode deixar este campo em branco",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Você precisa informar um e-mail válido",
      ],
      nome: [(v) => !!v || "Você não pode deixar este campo em branco"],
    },
    cadastroModel: {
      idUsuario: "",
      nome: null,
      email: null,
      tipoUsuario: null,
      empresa: { nome: null, apelido: "" },
      ativo: true,
    },
    snackbarModel: {
      mostrar: false,
      text: "",
      sucesso: false,
    },
    listaEmpresas: [],
    listaTipoUsuario: [],
    disableBtnFinalizar: false,
  }),
  computed: {
    perfilUsr: function () {
      return perfil;
    },
    formValid: function () {
      return this.formularioCadastroValido;
    },
    cipherComputed: function () {
      return cipher;
    },
  },
  watch: {
    $route() {
      const self = this;
      self.$refs.formularioCadastroRef.reset();
      this.cadastroModel.ativo = false;
    },
  },
  mounted: function () {
    this.buscarEmpresas();
    this.validaPerfilUsr();
    this.buscarUsuario();
  },
  methods: {
    validaPerfilUsr: function () {
      const self = this;
      const item = jwt.decryptToken();

      if (item.idtipousuario == perfil.ID.administrador) {
        self.listaTipoUsuario.push({
          idTipoUsuario: 1,
          nome: "Administrador",
        })
        self.listaTipoUsuario.push({
          idTipoUsuario: 4,
          nome: "Publico",
        })
      }
      if (item.idtipousuario == perfil.ID.cliente || item.idtipousuario == perfil.ID.administrador) {
        self.listaTipoUsuario.push({
          idTipoUsuario: 2,
          nome: "Cliente",
        })
        self.listaTipoUsuario.push({
          idTipoUsuario: 3,
          nome: "Operador",
        })
      }
      self.listaTipoUsuario.sort(customFormat.compareNome);

    },
    buscarEmpresas: function () {
      const self = this;
      const item = jwt.decryptToken();
      if (item != null) {
        if (item.idtipousuario == self.perfilUsr.ID.administrador) {
          self.$http
            .get(`${process.env.VUE_APP_URL_API}/empresa/busca/`, {
              headers: {
                Authorization:
                  `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then(function (response) { 
              self.listaEmpresas = response.data.filter(empresa => !empresa.contaPagamentoInterna);
              self.listaEmpresas.sort(customFormat.compareNome);
            });
        }
        else {
          self.$http
            .get(
              `${process.env.VUE_APP_URL_API}/usuario/busca/` +
              item.id,
              {
                headers: {
                  Authorization:
                    `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then(function (response) {
              const resposta = response.data[0].empresa;
              self.listaEmpresas.push(resposta);
            });
        }
      } else {
        self.go("Login");
      }
    },
    buscarUsuario: function () {
      const self = this;
      const item = jwt.decryptToken();
      if (item != null) {
        if (
          this.$route.params.id &&
          Number.isInteger(parseInt(cipher.decrypt(this.$route.params.id)))
        ) {
          self.$http
            .get(
              `${process.env.VUE_APP_URL_API}/usuario/busca/` +
              cipher.decrypt(this.$route.params.id),
              {
                headers: {
                  Authorization:
                    `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then(function (response) {
              const resposta = response.data[0];
              self.cadastroModel = resposta;
            });
        }
      } else {
        self.go("Login");
      }
    },
    finalizar: function () {
      const self = this;
      self.$store.commit("startLoading");
      if (self.$refs.formularioCadastroRef.validate()) {
        const item = jwt.decryptToken();
        if (item != null) {
          if (!self.$route.params.id) {
            self.cadastroModel.idUsuario = "";
            self.$http
              .post(
                `${process.env.VUE_APP_URL_API}/usuario/cadastro`,
                self.cadastroModel,
                {
                  headers: {
                    Authorization:
                      `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then(function (response) {
                const data = response.data;
                if (data.sucesso) {
                  self.disableBtnFinalizar = data.sucesso;
                  self.go("Usuarios", {
                    snackbarText: data.mensagem,
                    snackbarMostrar: true,
                    snackbarSucesso: data.sucesso,
                  });
                } else {
                  self.snackbarModel.text = data.mensagem;
                  self.snackbarModel.mostrar = true;
                  self.snackbarModel.sucesso = data.sucesso;
                  self.disableBtnFinalizar = data.sucesso;
                }
                self.$store.commit("endLoading");
              })
              .catch(function (error) {
                self.$store.commit("endLoading");
                self.snackbarModel.text = error.response.data.mensagem;
                self.snackbarModel.sucesso = false;
                self.snackbarModel.mostrar = true;
                self.disableBtnFinalizar = false;
              });
          } else {
            self.cadastroModel.idUsuario = cipher.decrypt(
              self.$route.params.id
            );
            self.$http
              .post(
                `${process.env.VUE_APP_URL_API}/usuario/edicao`,
                self.cadastroModel,
                {
                  headers: {
                    Authorization:
                      `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then(function (response) {
                const data = response.data;
                if (data.sucesso) {
                  self.disableBtnFinalizar = data.sucesso;
                  self.go("Usuarios", {
                    snackbarText: data.mensagem,
                    snackbarMostrar: true,
                    snackbarSucesso: data.sucesso,
                  });
                } else {
                  self.snackbarModel.text = data.mensagem;
                  self.snackbarModel.mostrar = true;
                  self.snackbarModel.sucesso = data.sucesso;
                  self.disableBtnFinalizar = data.sucesso;
                }
                self.$store.commit("endLoading");
              })
              .catch(function (error) {
                self.$store.commit("endLoading");
                self.snackbarModel.text = error.response.data.mensagem;
                self.snackbarModel.sucesso = false;
                self.snackbarModel.mostrar = true;
                self.disableBtnFinalizar = false;
              });
          }
        } else {
          self.go("Login");
        }
      }
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (!routeParams) {
        self.$router.push({ name: routeName });
      } else {
        self.$router.push({ name: routeName, params: routeParams });
      }
    },
  },
};
</script>
