import perfil from '@/helpers/perfil.js';
import usuarioHelper from '@/helpers/usuario.js';

export default {
  Home: {
    acesso: true
  },
  Login: {
    acesso: true
  },
  ErroPaginaNaoEncontrada: {
    acesso: true
  },
  SideMenu: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario >= perfil.ID.administrador
  },
  RecuperarSenha: {
    acesso: true
  },
  EsqueciSenha: {
    acesso: true
  },
  Portal: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario >= perfil.ID.administrador
  },
  Usuarios: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  CadastroUsuario: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  Empresas: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador
  },
  CadastroEmpresa: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador
  },
  AdminContas: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador
  },
  EmpresaContas: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  Funcionarios: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  CadastroFuncionario: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  CadastroFuncionarioLote: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  PropostasFuncionario: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  PropostasAdmin: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador
  },
  SimulacoesFuncionario: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  ContaHome: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  CadastroSplit: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador
  },
  FolhaPagamento: {
    acesso: true
  },
  CadastroFolhaPagamentoArquivo: {
    acesso: true
  },
}
