<template>
    <v-container>
        <v-layout align-center>
            <v-flex xs10>
                <h3 class="secondary--text">Contas</h3>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 md4 text-xs-right>
                <v-text-field label="Pesquisar" v-model="filtro" color="secondary" append-icon="search"></v-text-field>
            </v-flex>
        </v-layout>
        <v-layout justify-space-around row wrap>
            <v-flex xs12>
                <v-card class="default-border" flat text>
                    <v-data-table :headers="headers" :items="listaModelo" class="elevation-1 table-motoristas"
                        :search="filtro" :sort-by="['dataInclusao']" :sort-desc="[true]" :items-per-page="20"
                        :footer-props="{
                    itemsPerPageAllText: 'Todos',
                    itemsPerPageText: 'Itens por página',
                }">
                        <template v-slot:[`item.numeroDocumento`]="{ item }">
                            {{ customFormatComputed.formatCPFCNPJNumber(item.numeroDocumento) }}
                        </template>
                        <template v-slot:[`item.ativo`]="{ item }">
                            {{ item.ativo ? "Ativo" : "Inativo" }}
                        </template>
                        <template v-slot:[`item.telefone`]="{ item }">
                            {{ customFormatComputed.formatPhoneNumber(item.telefone.replace("+55","")) }}
                        </template>
                        <template v-slot:[`item.empresa`]="{ item }">
                            {{ item.empresa ? item.empresa.nome : "Sem Empresa" }}
                        </template>
                        <template v-slot:[`item.dataInclusao`]="{ item }">
                            {{
                    customFormatComputed.toLocaleDateTimePtBrString(item.dataInclusao)
                }}
                        </template>
                        <template v-slot:[`item.controle`]="{ item }">
                            <v-layout justify-center>
                                <v-tooltip color="secondary" bottom>
                                    <template v-slot:activator="{ on }">
                                        <td>
                                            <v-btn small depressed fab v-on="on" color="secondary"
                                                class="ma-2 px-1 icon-edit" @click="abrirModalStatus(item)">
                                                <v-icon style="font-size: 16px">mdi-cash-plus</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <span>Enviar dinheiro {{ item.nome }}</span>
                                </v-tooltip>
                            </v-layout>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
        <template>
            <v-dialog v-model="dialogStatus" persistent>
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Transferir valor para {{ cadastroModelo.nome }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-lg>
                            <v-layout row wrap>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12><b>Conta Destino</b></v-flex>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12 md4>Conta: {{ cadastroModelo.numeroConta }}</v-flex>
                                <v-flex xs12 md4>CPF/CNPJ: {{
                    customFormatComputed.formatCPFCNPJNumber(cadastroModelo.numeroDocumento)
                }}</v-flex>
                                <v-flex xs12 md4>Banco: {{ cadastroModelo.bankId }}</v-flex>
                                <v-flex xs12 md4>Telefone: {{ cadastroModelo.telefone }}</v-flex>
                                <v-flex xs12 md4>Email: {{ cadastroModelo.email }}</v-flex>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12><b>Conta Origem</b></v-flex>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12>Conta da: {{ usuarioLogado.nomeempresa }}</v-flex>
                                <v-flex xs12 md6>
                                    <v-text-field ref="txtvalorFormatado" v-model="valorFormatado" color="secondary"
                                        :rules="regras.branco" prepend-icon="mdi-currency-usd" label="Valor" type="text"
                                        required v-money="money"></v-text-field>
                                </v-flex>
                                <v-flex xs12 md6>
                                    <v-text-field v-model="cadastroTevModelo.pin" color="secondary"
                                        :rules="regras.branco" prepend-icon="mdi-lock-outline" label="PIN" type="text"
                                        v-mask="'######'" required></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="closeStatus">
                            Fechar
                        </v-btn>
                        <v-btn color="secondary" depressed :disabled="disableBtnFinalizar" @click="finalizar">
                            Salvar
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-dialog>
        </template>
        <v-snackbar :timeout="3200" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
            v-model="snackbarModel.mostrar" bottom multi-line>
            {{ snackbarModel.text }}
            <v-btn dark text @click.native="snackbarModel.mostrar = false">Fechar</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";
import usuarioHelper from "@/helpers/usuario.js";
import { VMoney } from 'v-money'

export default {
    name: "Empresas",
    data: () => ({
        dialogStatus: false,
        formularioCadastroValido: false,
        disableBtnFinalizar: false,
        valorFormatado: null,
        snackbarModel: {
            mostrar: false,
            sucesso: false,
            text: "",
        },
        filtro: "",
        headers: [
            { text: "Nome", value: "nome" },
            { text: "CPF", value: "numeroDocumento" },
            { text: "Conta", value: "numeroConta" },
            { text: "Telefone", value: "telefone" },
            { text: "Email", value: "email" },
            { text: "Empresa", value: "empresa" },
            { text: "Data Inclusão", value: "dataInclusao" },
            { text: "", value: "controle", sortable: false },
        ],
        cadastroModelo: {
            numeroConta: null,
            numeroDocumento: null,
            bankId: null,
            telefone: null,
            email: null,
            empresa: { nome: null }
        },
        cadastroTevModelo: {
            numeroConta: null,
            valor: null,
            pin: null
        },
        money: {
            decimal: ',',
            thousands: '.',
            prefix: 'R$ ',
            precision: 2,
            masked: false
        },
        regras: {
            branco: [(v) => !!v || "Você não pode deixar este campo em branco"]
        },
        listaModelo: []
    }),
    mounted: function () {
        this.buscar()
        this.externalSnackbarMessage()
    },
    directives: { money: VMoney },
    watch: {
        valorFormatado: function (val) {
            this.cadastroTevModelo.valor = customFormat.removeMoneyFormat(val);
        }
    },
    computed: {
        usuarioLogado: function () {
            return usuarioHelper.USUARIO_LOGADO();
        },
        customFormatComputed: function () {
            return customFormat;
        },
        cipherComputed: function () {
            return cipher;
        },
        formValid: function () {
            return this.formularioCadastroValido;
        },
    },
    methods: {
        abrirModalStatus(item) {
            const self = this;
            self.cadastroModelo = item
            self.cadastroTevModelo.numeroConta = item.numeroConta
            self.disableBtnFinalizar = false;
            self.dialogStatus = true;
        },
        closeStatus() {
            const self = this;
            self.cadastroModelo = {
                numeroConta: null,
                numeroDocumento: null,
                bankId: null,
                telefone: null,
                email: null,
                empresa: { nome: null }
            }

            self.$refs.txtvalorFormatado.$el.getElementsByTagName(
                "input"
              )[0].value = ("R$ 0,00");

            self.cadastroTevModelo.numeroConta = null;
            self.cadastroTevModelo.valor = null;
            self.cadastroTevModelo.pin = null;
            self.valorFormatado = null;

            self.dialogStatus = false;
        },
        finalizar() {
            const self = this
            self.$store.commit("startLoading");

            self.$http
                .post(
                    `${process.env.VUE_APP_URL_API}/conta/transferencia/tev`, self.cadastroTevModelo,
                    {
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then(function (response) {
                    const data = response.data
                    if (data.sucesso) {
                        self.snackbarModel.text = data.mensagem;
                        self.snackbarModel.mostrar = true;
                        self.snackbarModel.sucesso = data.sucesso;
                        self.disableBtnFinalizar = data.sucesso;
                        self.closeStatus()
                    } else {
                        self.snackbarModel.text = data.mensagem;
                        self.snackbarModel.mostrar = true;
                        self.snackbarModel.sucesso = data.sucesso;
                        self.disableBtnFinalizar = data.sucesso;
                    }
                    self.$store.commit("endLoading");
                })
                .catch(function (error) {
                    self.$store.commit("endLoading");
                    self.snackbarModel.text = error.data.mensagem;
                    self.snackbarModel.mostrar = true;
                    self.snackbarModel.sucesso = false;
                    console.log(error)
                })

        },
        buscar: function () {
            const self = this;
            if (
                this.$route.params.id &&
                Number.isInteger(parseInt(cipher.decrypt(this.$route.params.id)))
            ) {
                self.$http
                    .get(
                        `${process.env.VUE_APP_URL_API}/conta/busca/` +
                        cipher.decrypt(this.$route.params.id),
                        {
                            headers: {
                                Authorization:
                                    `Bearer ${localStorage.getItem("token")}`,
                            },
                        }
                    )
                    .then(function (response) {
                        self.listaModelo = response.data;
                    });
            } else {
                self.$store.commit("startLoading");
                self.$http
                    .get(`${process.env.VUE_APP_URL_API}/conta/busca`, {
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    })
                    .then(function (response) {
                        self.listaModelo = response.data;
                        self.$store.commit("endLoading");
                    }).catch(function (error) {
                        console.log(error.response.data)
                        self.$store.commit("endLoading")
                    });
            }
        },
        externalSnackbarMessage: function () {
            const self = this;
            if (self.$route.params.snackbarMostrar) {
                self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
                self.snackbarModel.text = self.$route.params.snackbarText;
                self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
            }
        },
        go: function (routeName, routeParams) {
            const self = this;
            if (!routeParams) {
                self.$router.push({ name: routeName });
            } else {
                self.$router.push({ name: routeName, params: routeParams });
            }
        },
        goEdit: function (id) {
            const self = this;
            self.$router.push({ name: "CadastroEmpresa", params: { id: id } });
        }
    }
};
</script>