<template>
  <v-container fluid fill-height :class="$vuetify.breakpoint.xsOnly ? 'container-menor' : ''">
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm6 md4>
        <v-card class="default-border">
          <v-toolbar text>
            <v-toolbar-title class="white--text">Recuperação de senha</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="esqueciSenhaForm" v-model="esqueciSenhaFormValid">
              <v-container grid-list-lg>
                <v-layout row wrap>
                  <v-flex xs12>
                    <span class="themeGrey--text">Insira o e-mail de cadastro. Em breve enviaremos
                      instruções para seu e-mail.</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field label="E-mail" v-model="esqueciSenhaModel.email" :rules="regras.email"
                      @keydown.enter.prevent="" validate-on-blur="" required>
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-flex xs12 md6 text-md-left text-xs-left>
              <v-btn text id="voltar" color="primary" class="default-border" @click="voltar" :disabled="loadingEnviar">
                Voltar
              </v-btn>
            </v-flex>
            <v-spacer></v-spacer>
            <v-btn :loading="loadingEnviar" @click.native="enviarRecuperacaoSenha" :disabled="!esqueciSenhaFormValid ||
    (snackbar.visivel && snackbar.sucesso) ||
    loadingEnviar
    " class="default-border" color="primary" depressed>
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-snackbar :timeout="6000" :color="snackbar.sucesso ? 'success' : 'error'" multi-line
          v-model="snackbar.visivel">
          {{ snackbar.mensagem }}
          <v-btn dark text @click.native="snackbar.visivel = false">Fechar</v-btn>
        </v-snackbar>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "login",
  data: () => {
    return {
      esqueciSenhaFormValid: false,
      loadingEnviar: false,
      snackbar: {
        visivel: false,
        mensagem: "",
        sucesso: false,
      },
      esqueciSenhaModel: {
        email: "",
      },
      regras: {
        email: [
          (v) => !!v || "Você não pode deixar este campo em branco",
          (v) =>
            /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,3})$/.test(
              v
            ) || "Formato de e-mail inválido",
        ],
      },
    };
  },
  watch: {
    "snackbar.visivel": function (newValue, oldValue) {
      if (oldValue && !newValue && this.snackbar.sucesso) {
        this.$router.push("/Login");
      }
    },
  },
  methods: {
    voltar: function () {
      this.$router.push("/login");
    },
    enviarRecuperacaoSenha: function () {
      const self = this;
      self.loadingEnviar = true;
      axios
        .get(
          `${process.env.VUE_APP_URL_API}/no-auth/authentication/enviar-link-reset-senha?email=${self.esqueciSenhaModel.email}`
        )
        .then((response) => {
          const existe = response.data;
          console.log(response.data)
          if (existe) {
            self.go("Login", {
              snackbarText:
                "As instruções foram enviadas para o seu e-mail",
              snackbarMostrar: true,
              snackbarSucesso: true,
            });
          } else {
            self.snackbar.visivel = true;
            self.snackbar.sucesso = false;
            self.snackbar.mensagem = "Erro ao enviar e-mail de recuperação";
            self.loadingEnviar = false;
          }
        })
        .catch(() => {
          self.snackbar.visivel = true;
          self.snackbar.sucesso = false;
          self.snackbar.mensagem = "Erro ao enviar e-mail de recuperação";
          self.loadingEnviar = false;
        });
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (!routeParams) {
        self.$router.push({ name: routeName });
      } else {
        self.$router.push({ name: routeName, params: routeParams });
      }
    },
  },
};
</script>

<style scoped>
.container-menor {
  padding: 8px !important;
}
</style>
