<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm8>
        <v-card class="elevation-12 default-border">
          <v-toolbar color="secondary">
            <v-toolbar-title>
              Cadastro de Arquivo de Folha Pagamento
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="formularioCadastroValido" ref="formularioCadastroRef">
              <v-container grid-list-lg>
                <v-layout row wrap>
                  <v-flex xs12>
                    <a href="/file/modelo_pagamento_folha.csv" download>Download Modelo</a>
                  </v-flex>
                  <v-flex xs12>
                    <v-file-input v-model='file' show-size label="Upload arquivos de pagamento de funcionário"
                      accept=".csv" required @change="validaFile"></v-file-input>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn id="voltar" color="primary" class="grey--text text--darken-1" :to="{ name: 'FolhaPagamento' }"
              text>Voltar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="finalizar" color="secondary" class="default-border" depressed @click="validaArquivo"
              :disabled="file == null || disableBtnValidarFinalizar || !formValid">Validar</v-btn>
            <v-btn id="finalizar" color="secondary" class="default-border" depressed
              :disabled="file == null || disableBtnSalvarFinalizar || !formValid"
              @click="processoArquivo">Salvar</v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="default-border" flat text>
          <v-data-table :headers="headers" :items="listaModelo" class="elevation-1 table-motoristas"
            :sort-by="['linha']" :sort-desc="[true]" :items-per-page="20" :footer-props="{
              itemsPerPageAllText: 'Todos',
              itemsPerPageText: 'Itens por página',
            }">
            <template v-slot:[`item.erros`]="{ item }">
              {{ item.erros.map(item => {
              return `Campo: ${item.campo}, Valor: ${item.valor}, Mensagem: ${item.mensagem}`;
            }).join(' | ') }}
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar :timeout="5000" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`" bottom multi-line
      v-model="snackbarModel.mostrar">
      {{ snackbarModel.text }}
      <v-btn text dark @click.native="
              `${snackbarModel.sucesso
                ? go('FolhaPagamento')
                : (snackbarModel.mostrar = false)
              }`
              ">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import customFormat from "@/helpers/custom-format.js";
import jwt from "@/helpers/jwt";

export default {
  name: "CadastroFolhaPagamentoArquivo",
  data: () => ({
    formularioCadastroValido: false,
    disableBtnSalvarFinalizar: true,
    disableBtnValidarFinalizar: false,
    file: null,
    snackbarModel: {
      mostrar: false,
      text: "",
      sucesso: false,
    },
    headers: [
      { text: "Linha", value: "linha" },
      { text: "Erros", value: "erros" },
    ],
    listaModelo: [],
    regras: {
      tamanho: [(v) => !!v || v.size < 1000000 || 'Arquivo não pode passa de 1 MB!']
    },
  }),
  computed: {
    formValid: function () {
      return this.formularioCadastroValido;
    },
    customFormatComputed: function () {
      return customFormat;
    },
  },
  mounted: function () {
  },
  methods: {
    validaFile()
    {
      const self = this;
      if (!self.disableBtnSalvarFinalizar && self.file == null) {
        self.disableBtnSalvarFinalizar = true;
        self.disableBtnValidarFinalizar = false;
      }
    },
    validaArquivo() {
      const self = this;
      self.$store.commit("startLoading");
      if (self.$refs.formularioCadastroRef.validate()) {
        const item = jwt.decryptToken();
        if (item != null) {
          const formData = new FormData();
          formData.append('uploadfile', this.file);
          self.$http
            .post(
              `${process.env.VUE_APP_URL_API}/folha-pagamento/upload/validar`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization:
                    `Bearer ${localStorage.getItem("token")}`
                },
              }
            )
            .then(function (response) {
              self.$store.commit("endLoading");
              const data = response.data;
              if (data.sucesso) {
                self.listaModelo = data.listaValidacao;
                self.disableBtnValidarFinalizar = data.sucesso;
                self.snackbarModel.text = data.mensagem;
                self.snackbarModel.mostrar = true;
                self.snackbarModel.sucesso = data.sucesso;
                self.disableBtnSalvarFinalizar = false;
              } else {
                self.listaModelo = data.listaValidacao;
                self.snackbarModel.text = data.mensagem;
                self.snackbarModel.mostrar = true;
                self.snackbarModel.sucesso = data.sucesso;
                self.disableBtnSalvarFinalizar = data.sucesso;
              }
            })
            .catch(function (error) {
              self.$store.commit("endLoading");
              self.snackbarModel.text = error.response.data.mensagem;
              self.snackbarModel.sucesso = false;
              self.snackbarModel.mostrar = true;
              self.disableBtnSalvarFinalizar = false;
            });
        } else {
          self.go("Login");
        }
      }
    },
    processoArquivo() {
      const self = this;
      self.$store.commit("startLoading");
      if (self.$refs.formularioCadastroRef.validate()) {
        const item = jwt.decryptToken();
        if (item != null) {
          const formData = new FormData();
          formData.append('uploadfile', this.file);
          self.$http
            .post(
              `${process.env.VUE_APP_URL_API}/folha-pagamento/upload/processar`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization:
                    `Bearer ${localStorage.getItem("token")}`
                },
              }
            )
            .then(function (response) {
              self.$store.commit("endLoading");
              const data = response.data;
              if (data.sucesso) {
                self.disableBtnSalvarFinalizar = data.sucesso;
                self.go("FolhaPagamento", {
                    snackbarText: data.mensagem,
                    snackbarMostrar: true,
                    snackbarSucesso: data.sucesso,
                  });
              } else {
                self.snackbarModel.text = data.mensagem;
                self.snackbarModel.mostrar = true;
                self.snackbarModel.sucesso = data.sucesso;
                self.disableBtnSalvarFinalizar = data.sucesso;
              }
            })
            .catch(function (error) {
              self.$store.commit("endLoading");
              self.snackbarModel.text = error.response.data.mensagem;
              self.snackbarModel.sucesso = false;
              self.snackbarModel.mostrar = true;
              self.disableBtnFinalizar = false;
            });
        } else {
          self.go("Login");
        }
      }
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (this.$router.currentRoute.name != routeName) {
        if (!routeParams) {
          self.$router.push({ name: routeName });
        } else {
          self.$router.push({ name: routeName, params: routeParams });
        }
      }
    },
  },
};
</script>