/* eslint-disable no-unused-vars */
import Axios from "axios";
import jwt from "@/helpers/jwt";
import usuarioHelper from '@/helpers/usuario.js';
import permissoes from '@/helpers/permissoes.js';

export default {
  auth: function (to, from, next) {
    const token = localStorage.getItem('token');
    if (to.meta.requiresAuth) {
      if (!token) {
        next({ name: 'Login' });
      }
      else {
        var tokenObject = jwt.decryptToken();
        if (!tokenObject || !tokenObject.id) {
          localStorage.removeItem('token');
          next({ name: 'Login' });
        }
      }
    }
    else if (to.name === 'Login' && token)
      next({ name: 'Portal' });
    next();
  },
  permissaoAcesso: function (to, from, next) {
    if (to.params.login) { //Dá refresh na página na ação de login.
      location.reload();
    }
    else {
      const permissionObj = permissoes;
      const acessoPermitidoStr = "." + to.name + ".acesso";
      const permissionPageTo = eval("permissionObj" + acessoPermitidoStr);

      if (!permissionPageTo == true) {
        if (usuarioHelper.USUARIO_LOGADO()) {
          next({ name: 'ErroPaginaNaoEncontrada' });
        } else {
          next({ name: 'Login' })
        }
      }
    }
    next();
  }
}
