<template>
  <v-app>
    <v-main>
      <Toolbar v-if="!$route.meta.hideToolbar"></Toolbar>
      <Loading></Loading>
      <transition appear mode="out-in" name="fade">
        <router-view/>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import Toolbar from '@/components/compartilhados/Toolbar'
import Loading from '@/components/compartilhados/Loading'

export default {
  name: 'App',
  components: {
    Toolbar,
    Loading
  },
  meta: [
    { equiv: 'Content-Type', content: 'text/html; charset=utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' }
  ],
  created() {
    console.log(process.env.VUE_APP_URL_API);
  }
}
</script>

<style>
#app {
  background: #e6e7e8;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
